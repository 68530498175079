export function SelectInput({
  data,
  ...props
}: React.InputHTMLAttributes<HTMLSelectElement> & {
  data: { value: string; label: string }[];
}) {
  return (
    <div className="w-full">
      <select
        {...props}
        className={`h-[48px] !outline-none w-full focus:bg-white rounded-lg border border-[#D9D9E7] focus:border-grey2 p-3 text-base text-black ${
          props.className || ""
        }`}
      >
        <option value="" disabled>
          {props.placeholder || "Select an option"}
        </option>
        {data.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}
