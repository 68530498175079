import { SortableHandle } from "react-sortable-hoc";
import { ReactComponent as Drag } from "../../../../assets/icons/drag.svg";

const RowHandler = SortableHandle(() => (
  <div className="handle cursor-grab flex items-stretch justify-center">
    <div className="bg-[#E4EBE4] w-[44px] flex justify-center items-center rounded-md">
      <Drag />
    </div>
  </div>
));

const DraggableRow = ({ data, number, total, onEdit, onDelete }: any) => {
  return (
    <div className="flex items-stretch gap-1.5 pt-3 noselect z-[99]">
      <div className="flex items-stretch">
        <RowHandler />
      </div>
      <div className="pl-3 w-full pr-[21px] py-3 bg-white rounded-xl border border-[#e4ebe4] justify-start items-center gap-4 inline-flex">
        <img
          className="w-[72px] h-[72px] rounded-lg"
          src={data?.thumb_image}
          alt="banner-img"
        />
        <div className="grow shrink basis-0 flex-col justify-center items-start gap-1.5 inline-flex">
          <div className="self-stretch text-black text-[15px] font-semibold font-['Montserrat'] leading-snug">
            {data?.title}
          </div>
          <div className="text-[#444444] text-sm font-medium font-['Montserrat'] leading-[21px]">
            Lesson {number}/{total}
          </div>
        </div>
        <div className="flex-col justify-center items-end gap-[9px] inline-flex">
          <button
            onClick={() => onEdit(data)}
            className="text-[#355e3b] text-sm font-medium font-['Montserrat']"
          >
            Edit
          </button>
          <button
            onClick={() => onDelete(data)}
            className="text-[#f54242] text-sm font-medium font-['Montserrat']"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  );
};

export default DraggableRow;
