import { useRef, useState } from "react";
import { ReactComponent as Upload } from "../../../../assets/icons/upload.svg";
import { TextInput } from "../../../../components/TextInput";
import { fileUpload } from "../../../../helpers/fileUpload";
import toast from "react-hot-toast";
import Spinner from "../../../../components/Spinners";

export function VideoForm({
  onChange,
  data,
}: {
  onChange: (data: any) => void;
  data: any;
}) {
  const [coverFile, setCoverFile] = useState<File | null>(null);
  const [videoUploading, setVideoUploading] = useState(false);
  const [coverUploading, setCoverUploading] = useState(false);
  const videoInputRef = useRef<HTMLInputElement>(null);
  const coverInputRef = useRef<HTMLInputElement>(null);
  const [showYoutubeUrl, setShowYoutubeUrl] = useState(false);

  const handleUpload = () => {
    setShowYoutubeUrl(false);
  };

  const handleCoverUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];
      if (file) {
        setCoverFile(file);
        setCoverUploading(true);
        const { response, url } = await fileUpload(file, "SUB_VIDEO_COVER");
        setCoverUploading(false);
        onChange({ ...data, thumb_image: url });
      }
    } catch (error) {
      setCoverUploading(false);
      toast.error("Failed to upload thumb image");
    }
  };

  const handleVideoUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = e.target.files?.[0];
      if (file) {
        // upload file
        setVideoUploading(true);
        const { response, url } = await fileUpload(file, "SUB_VIDEO");
        setVideoUploading(false);
        onChange({ ...data, url });
      }
    } catch (error) {
      setVideoUploading(false);
      toast.error("Failed to upload video");
    }
  };

  const coverUrl = data.thumb_image
    ? data.thumb_image
    : coverFile
    ? URL.createObjectURL(coverFile)
    : "";

  return (
    <div className="pl-5 pr-[29px] py-5 bg-white rounded-xl border border-[#e4ebe4] justify-start items-start gap-5 inline-flex">
      <div className="justify-start items-start gap-5 flex w-full">
        <div className="flex-col justify-start items-start gap-1.5 flex">
          <button
            onClick={() => coverInputRef.current?.click()}
            className="w-[105px] h-[105px] bg-[#e4ebe4] rounded-[7px] border border-dashed border-[#355e3b] flex-col justify-center items-center gap-2 flex"
          >
            {coverUploading ? (
              <Spinner />
            ) : !!coverUrl ? (
              <img
                src={coverUrl}
                alt="thumb_image"
                className="w-full h-full object-cover"
              />
            ) : (
              <>
                <Upload className="w-6 h-6" />
                <div className="text-[#355e3b] text-[11px] font-medium font-['Montserrat'] leading-[11px]">
                  Upload Cover
                </div>
              </>
            )}
          </button>
          <input
            type="file"
            ref={coverInputRef}
            className="hidden"
            onChange={handleCoverUpload}
          />
        </div>
        <div className="flex-col justify-start items-start gap-3 flex flex-1">
          <div className="self-stretch [&>div]:flex-1 justify-start items-center gap-2.5  w-full flex">
            {showYoutubeUrl ? (
              <TextInput
                placeholder="Enter youtube video url"
                value={data.url}
                onChange={(e) => onChange({ ...data, url: e.target.value })}
                className="flex-1"
              />
            ) : (
              <>
                <button
                  onClick={() => videoInputRef.current?.click()}
                  className="flex-1 h-[46px] py-[13.25px] bg-[#e4ebe4] gap-[7px] rounded-[7px] border border-dashed border-[#355e3b] justify-center items-center flex"
                >
                  {videoUploading ? (
                    <Spinner />
                  ) : (
                    <Upload className="w-5 h-5" />
                  )}
                  <div className="flex-1 overflow-hidden whitespace-nowrap text-ellipsis max-w-[200px] opacity-80 text-black text-xs font-medium font-['Montserrat'] leading-none">
                    {data?.url?.split("/")?.at(-1) || "Upload video"}
                  </div>
                </button>
                <input
                  type="file"
                  ref={videoInputRef}
                  className="hidden"
                  onChange={handleVideoUpload}
                />
              </>
            )}
            <button
              onClick={() => {
                onChange({ ...data, url: "" });
                setShowYoutubeUrl(!showYoutubeUrl);
              }}
              disabled={videoUploading}
              className="opacity-80 text-[#608965] text-xs font-semibold font-['Montserrat'] leading-none"
            >
              {showYoutubeUrl ? "Add video instead" : "Add youtube URL instead"}
            </button>
          </div>
          <TextInput
            placeholder="Enter title of the lesson"
            className="!border-[#D9D9E7]"
            value={data.title}
            onChange={(e) => onChange({ ...data, title: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
}
