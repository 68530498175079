import { useState } from "react";
import FullScreenModal from "../../../components/FullScreenModal";
import { TABS, tabs, UserDetailsTabs } from "./components/Tabs";
import { api } from "../../../helpers/api";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { ShowToast } from "../../../components/toast";
import { Banner } from "./components/Banner";
import { LikedPosts } from "./components/LikedPosts";
import { ConfirmDeletePost } from "./components/ConfirmDeletePost";
import { ConfirmBlock, ConfirmDelete } from "../UserDetails";

export function UserDetails({ isOpen, onClose, user, refresh }) {
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(tabs.About);
  const [deletePostConfirmation, setDeletePostConfirmation] =
    useState<any>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<any>(false);
  const [isDeletingPost, setIsDeletingPost] = useState(false);
  // const handleDelete = () => {
  //   setIsDeletingPost(true);
  //   api
  //     .deletePost(deletePostConfirmation?.id)
  //     .then(() => {
  //       ShowToast({
  //         message: "Post deleted successfully",
  //         type: "success",
  //       });
  //       refresh();
  //       setDeletePostConfirmation(false);
  //       queryClient.invalidateQueries({ queryKey: ["posts"] });

  //       setIsDeletingPost(false);
  //     })
  //     .catch(() => {
  //       ShowToast({
  //         message: "Failed to delete post",
  //         type: "error",
  //       });
  //       setIsDeletingPost(false);
  //     });
  // };
  const { data: userDetailsData, isLoading } = useQuery({
    queryKey: ["chart", user?.user_id],
    queryFn: () =>
      api.getUser({
        id: user?.user_id,
      }),
    enabled: !!user,
  });
  const { data: likedData } = useQuery({
    queryKey: ["liked-posts", user?.user_id],
    queryFn: () =>
      api.getUserLikedCourses({
        id: user?.user_id,
        page: 1,
        limit: 10,
      }),
    enabled: !!user,
  });

  // useEffect(() => {
  //   if (isOpen) {
  //     setSelectedTab(TABS.ABOUT);
  //   }
  // }, [isOpen]);

  // const details = userDetailsData?.data;
  // console.log(userDetailsData, "userDetailsData", user, likedData, savedData);
  // return (
  //   <div
  //     className={`fixed inset-0 flex bg-gray-900 bg-opacity-50 z-50 pt-12`}
  //     style={{
  //       zIndex: 50,
  //     }}
  //     onClick={() => onClose()}
  //   >
  //     <button
  //       onClick={onClose}
  //       className="absolute top-4 right-4 text-white hover:text-white/80 z-10 transition-all"
  //     >
  //       close
  //     </button>
  //     <div
  //       className={`full-screen-modal-content bg-[#F9F9F9] rounded-tl-lg rounded-tr-lg shadow-lg w-full relative z-51 h-[100%] md:p-0 p-4 overflow-auto`}
  //     >
  //       <div className="h-[calc(100%-35px)]">
  //         {/* Adjusted height to account for fixed button */}
  //         <div className="max-w-[898px] mx-auto mt-[34px]">
  //           <Banner
  //             userType={type}
  //             details={user}
  //             onDeactivate={() => setIsBlockModalOpen(true)}
  //             onDelete={() => setIsDeleteModalOpen(true)}
  //             user={user}
  //             onReview={() => setShowReviewModal(true)}
  //           />
  //           <UserDetailsTabs
  //             type={type}
  //             selectedTab={selectedTab}
  //             setSelectedTab={setSelectedTab}
  //             values={{
  //               Posts: user?.totalPosts,
  //               Liked: 10,
  //               Saved: 10,
  //             }}
  //           />
  //           {/* {selectedTab === TABS.LIKED && (
  //             <>
  //               <LikedPosts user={user} refresh={refresh} type={TABS.LIKED} />
  //             </>
  //           )}
  //           {selectedTab === TABS.SAVED && (
  //             <>
  //               <LikedPosts user={user} refresh={refresh} type={TABS.SAVED} />
  //             </>
  //           )} */}
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // );

  console.log(isOpen, "isOpen", user, likedData, userDetailsData);
  return (
    <>
      <div className="fixed inset-0 flex bg-gray-900 bg-opacity-50 z-50 pt-12">
        <FullScreenModal isOpen={isOpen} onClose={onClose}>
          <div className="max-w-[898px] mx-auto mt-[34px]">
            <Banner
              details={user}
              onDeactivate={() => setIsBlockModalOpen(true)}
              onDelete={() => setIsDeleteModalOpen(true)}
              user={user}
              onReview={() => setShowReviewModal(true)}
            />
            <UserDetailsTabs
              selectedTab={selectedTab}
              setSelectedTab={setSelectedTab}
              values={{
                Posts: user?.totalPosts,
                Liked: likedData?.data?.videoLikesCount,
                Saved: likedData?.data?.videoSavedCount,
              }}
            />
            {selectedTab === TABS.ABOUT && (
              <div>
                <div className="mt-4 flex p-5  bg-white rounded-[12px]">
                  <div className="flex flex-col gap-4">
                    <div className="text-black text-lg font-bold font-['Montserrat']">
                      Course Level
                    </div>
                    <div className="flex items-center gap-2 flex-wrap">
                      {userDetailsData?.data?.data?.course_level_names?.map(
                        (level, i) => (
                          <div
                            key={i}
                            className="h-7 px-2.5 py-1.5 text-[#404849] text-[13px] font-medium font-['Montserrat'] leading-none bg-[#f8f8f8] rounded-md border border-[#414949]/10"
                          >
                            {level}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="mt-4 flex p-5  bg-white rounded-[12px]">
                  <div className="flex flex-col gap-4">
                    <div className="text-black text-lg font-bold font-['Montserrat']">
                      Topics
                    </div>
                    <div className="flex items-center gap-2 flex-wrap">
                      {userDetailsData?.data?.data?.topic_names?.map(
                        (level, i) => (
                          <div
                            key={i}
                            className="h-7 px-2.5 py-1.5 text-[#404849] text-[13px] font-medium font-['Montserrat'] leading-none bg-[#f8f8f8] rounded-md border border-[#414949]/10"
                          >
                            {level}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {selectedTab === TABS.LIKED && (
              <>
                <LikedPosts user={user} refresh={refresh} type={TABS.LIKED} />
              </>
            )}
            {selectedTab === TABS.SAVED && (
              <>
                <LikedPosts user={user} refresh={refresh} type={TABS.SAVED} />
              </>
            )}
          </div>
        </FullScreenModal>
        {/* {deletePostConfirmation.id && (
          <ConfirmDeletePost
            onClose={() => setDeletePostConfirmation(false)}
            refetch={refresh}
            postId={deletePostConfirmation.id}
            onDelete={() => handleDelete()}
            isLoading={isDeletingPost}
          />
        )} */}

        {isDeleteModalOpen && (
          <ConfirmDelete
            userId={user?.user_id || ""}
            refetch={() => {
              // queryClient.invalidateQueries({ queryKey: ["users"] });
              // queryClient.invalidateQueries({
              //   queryKey: ["chart", user?.user_id],
              // });
              refresh();
              onClose();
            }}
            onClose={() => setIsDeleteModalOpen(false)}
            name={user?.fullName}
          />
        )}
        {isBlockModalOpen && (
          <ConfirmBlock
            userId={user?.user_id || ""}
            refetch={() => {
              // queryClient.invalidateQueries({ queryKey: ["users"] });
              // queryClient.invalidateQueries({
              //   queryKey: ["chart", user?.user_id],
              // });
              refresh();
              onClose();
            }}
            onClose={() => setIsBlockModalOpen(false)}
            name={user?.fullName}
          />
        )}
      </div>
    </>
  );
}
