import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { Header } from "../components/Header";
import React, { useState } from "react";
import { ReactComponent as AlertIcon } from "../../../assets/icons/Alert.svg";
import { ReactComponent as LockIcon } from "../../../assets/icons/lock.svg";
import { ReactComponent as EmailIcon } from "../../../assets/icons/email.svg";
import { Password } from "../components/Password";
import passwordValidation, { setToken, setUser } from "../../../helpers/utils";
import { api } from "../../../helpers/api";
import { TextInput } from "../../../components/TextInput";

export function Login() {
  const navigate = useNavigate();
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string>("");
  const [errors, setErrors] = useState({} as any);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    setError("");
    setLoading(true);
    try {
      const response = await api.login({
        email_id: data.email,
        password: data.password,
      });
      if (response.status === 401) {
        setError("Invalid credentials");
        setLoading(false);
        return;
      }
      const result = response.data;
      if (result?.token) {
        setToken(result?.token);
        if (result?.id) {
          setUser({ ...result });
        }
        navigate("/dashboard");
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError("Something went wrong");
      return false;
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password: "Invalid password",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      data.email &&
      data.password &&
      passwordValidation(data.password.toString())
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <div className="h-screen bg-secondary overflow-auto">
      <Header />
      <div className="py-10 px-4 md:px-0 h-[calc(100%-70px)] flex justify-center items-center">
        <div className="flex justify-center items-center">
          <div className="w-[442px]">
            <div className="bg-white p-8 rounded-[16px]">
              <div>
                <span className="text-black text-[28px] font-semibold font-['Montserrat'] leading-[30.80px]">
                  Welcome to{" "}
                </span>
                <span className="text-[#355e3b] text-[28px] font-bold font-['Montserrat'] leading-[30.80px]">
                  EasyEd
                </span>
              </div>
              <div className="w-[290px] text-[#666666] text-sm font-medium font-['Montserrat'] leading-none mt-3">
                Enter the details below
              </div>
              <form
                className="mt-4"
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                {error && (
                  <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    <AlertIcon /> {error}
                  </div>
                )}
                <div className="mb-[22px] flex flex-col gap-1.5">
                  <label
                    htmlFor="email"
                    className="text-[13px] text-black font-medium"
                  >
                    Email address
                  </label>
                  <TextInput
                    type="email"
                    name="email"
                    placeholder="Enter your email address"
                    icon={<EmailIcon />}
                  />
                </div>
                <div className=" flex flex-col gap-1.5">
                  <label htmlFor="password" className="text-sm text-black/80">
                    Password
                  </label>
                  <Password
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                    icon={<LockIcon />}
                  />
                  {errors.password && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.password}
                    </div>
                  )}
                </div>
                <div className="mt-3 flex justify-end">
                  <a
                    href="/forgot-password"
                    className="text-right text-[#666666] hover:text-black/80 text-sm font-semibold leading-none"
                  >
                    Forgot Password?
                  </a>
                </div>
                <div className="mt-7">
                  <Button
                    type="submit"
                    variant="primary"
                    className="!rounded-full w-full"
                    disabled={disabled || loading}
                    loading={loading}
                  >
                    Login
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
