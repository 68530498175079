import { Button } from "../../../../components/Button";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/email.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone.svg";
import moment from "moment";

export function Banner({ details, onDeactivate, onDelete, user, onReview }) {
  return (
    <div>
      <div className="w-[898px] mb-6 p-5 rounded-[16px] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.10)] bg-white justify-start items-stretch gap-3 flex relative">
        <div className="h-[93px] w-[93px] rounded-[12px]">
          <img
            src={details?.user_image || "/profile.jpeg"}
            className="w-full h-full rounded-[12px] object-fill"
            alt="user"
          />
        </div>
        <div className="flex-1">
          <div className="flex-col justify-start items-start gap-2 flex">
            <div className="justify-start items-center gap-2 flex">
              <div className="text-black text-lg font-bold ">
                {details?.first_name} {details?.last_name}
              </div>
              <div className="text-black text-sm font-normal ">
                @{details?.user_name}
              </div>
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <EmailIcon />
              <div className="text-[#202020] text-sm font-normal ">
                {details?.email_id}
              </div>
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <PhoneIcon />
              <div className="w-[212px] text-[#202020] text-sm font-normal ">
                {details?.mobile}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-between">
          <div className="flex justify-between">
            <div className="items-center gap-4 flex">
              <Button
                variant="link"
                className="text-right !text-[#444444] text-sm font-medium poppins"
                onClick={onDeactivate}
              >
                Deactivate user
              </Button>
              <div className="h-3.5 w-[1px] bg-black/20" />
              <Button
                variant="link"
                className="text-right !text-[#d54949] text-sm font-medium poppins"
                onClick={onDelete}
              >
                Delete user
              </Button>
            </div>
          </div>
          <div>
            <div className="justify-start items-center gap-3 flex">
              <div className="text-right">
                <span className="text-[#777777] text-xs font-normal font-['Montserrat']">
                  Last activity:{" "}
                </span>
                <span className="text-[#202020] text-xs font-normal font-['Montserrat']">
                  {moment(details.last_active_date).format(
                    "MMM DD, YYYY | hh:mm A"
                  )}
                </span>
              </div>
              <div className="h-2.5 w-[1px] bg-black/20" />
              <div className="text-right">
                <span className="text-[#777777] text-xs font-normal font-['Montserrat']">
                  Joined on:{" "}
                </span>
                <span className="text-[#202020] text-xs font-normal font-['Montserrat']">
                  {moment(details.date_created).format("MMM DD, YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
