import { Button } from "../../../../components/Button";
import { ReactComponent as EmailIcon } from "../../../../assets/icons/email.svg";
import { ReactComponent as PhoneIcon } from "../../../../assets/icons/phone.svg";
import moment from "moment";

export function Banner({ details, onDeactivate, onDelete }) {
  return (
    <div>
      <div className="w-[898px] mb-6 p-5 rounded-[16px] shadow-[0px_0px_10px_0px_rgba(0,0,0,0.10)] bg-white justify-start items-stretch gap-3 flex relative">
        <div className="h-[100px] w-20 rounded-md">
          <img
            src={details?.thumb_image || "/profile.jpeg"}
            className="w-full h-full rounded-md object-fill"
            alt="user"
          />
        </div>
        <div className="flex-1">
          <div className="flex-col justify-start items-start gap-2 flex">
            <div className="justify-start items-center gap-2 flex">
              <div className="text-black text-lg font-bold ">
                {details?.title}
              </div>
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              {details?.course_levels?.[0] && (
                <>
                  <div className="h-[26px] px-2 py-1 bg-[#edeaff] rounded justify-center items-center gap-2.5 text-black text-[13px] font-medium font-['Montserrat'] leading-[18.20px] flex">
                    {details?.course_levels?.[0]}
                  </div>
                  <div className="h-[26px] w-[1px] bg-[#E5E5E5]" />
                </>
              )}
              <div className="flex items-center gap-2">
                {details?.topics?.slice(0, 2).map((topic, i) => (
                  <div
                    key={i}
                    className="h-[26px] px-2 py-1 text-center text-black text-[13px] font-medium font-['Montserrat'] leading-[18.20px] bg-[#f8f8f8] rounded border border-[#414949]/10 justify-center items-center gap-2.5 inline-flex"
                  >
                    {topic}
                  </div>
                ))}
                {details?.topics?.length > 2 && (
                  <div className="h-[26px] px-2 py-1 text-center text-black text-[13px] font-medium font-['Montserrat'] leading-[18.20px] bg-[#f8f8f8] rounded border border-[#414949]/10 justify-center items-center gap-2.5 inline-flex">
                    +{details?.topics?.length - 2}
                  </div>
                )}
              </div>
            </div>
            <div className="justify-center items-center gap-2 inline-flex">
              <div className="w-[188px] h-[29px] justify-start items-center gap-2 inline-flex">
                <img
                  className="w-[29px] h-[29px] rounded"
                  src={details?.publisher_data?.user_image || "/profile.jpeg"}
                  alt="user"
                />
                <div className="text-black text-[13px] font-medium font-['Montserrat']">
                  By {details?.publisher_data?.full_name}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-end justify-between">
          <div className="flex justify-between">
            <div className="items-center gap-4 flex">
              <Button
                variant="link"
                className="text-right !text-[#444444] text-sm font-medium poppins"
                onClick={onDeactivate}
              >
                Archive
              </Button>
              <div className="h-3.5 w-[1px] bg-black/20" />
              <Button
                variant="link"
                className="text-right !text-[#d54949] text-sm font-medium poppins"
                onClick={onDelete}
              >
                Delete
              </Button>
            </div>
          </div>
          <div>
            <div className="justify-start items-center gap-3 flex">
              <div className="text-right">
                <span className="text-[#777777] text-xs font-normal font-['Montserrat']">
                  Added on:{" "}
                </span>
                <span className="text-[#202020] text-xs font-normal font-['Montserrat']">
                  {moment(details.date_created).format("MMM DD, YYYY")}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
