import { Tabs } from "../../UserTabs";

export const TABS = {
  ABOUT: "About",
  LIKED: "Liked",
  SAVED: "Saved",
};

export const tabs = {
  About: TABS.ABOUT,
  Liked: TABS.LIKED,
  Saved: TABS.SAVED,
};

export function UserDetailsTabs({ selectedTab, setSelectedTab, values }) {
  return (
    <div className="p-1 mb-4 w-fit rounded-[10px] border border-[#d7d7d7] justify-start items-center gap-px flex">
      {Object.values(tabs).map((tab) => (
        <button
          onClick={() => setSelectedTab(tab)}
          key={tab}
          className={`w-fit px-5 py-3.5 hover:bg-[#C1E0C5]/20 text-black  ${
            selectedTab === tab ? "!bg-[#C1E0C5]" : ""
          } rounded-lg  text-sm font-medium justify-center items-center gap-2.5 flex`}
        >
          {tab}
          {values[tab] ? `(${values[tab]})` : ""}
        </button>
      ))}
    </div>
  );
}
