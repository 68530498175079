import { useState } from "react";
import { ShowToast } from "../../../../components/toast";
import { Button } from "../../../../components/Button";
import { PopupModal } from "../../../../components/PopupModal";
import { api } from "../../../../helpers/api";

export function LessonConfirmDelete({
  onClose,
  lessonId,
  refetch,
  name,
}: {
  onClose: () => void;
  lessonId: string;
  refetch: () => void;
  name: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = () => {
    setIsLoading(true);
    api
      .deleteLesson(lessonId)
      .then(() => {
        onClose();
        ShowToast({
          message: "Lesson deleted successfully",
          type: "success",
        });
        refetch && refetch();
        setIsLoading(false);
      })
      .catch(() => {
        ShowToast({
          message: "Failed to delete lesson",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <PopupModal
      onClose={onClose}
      contentClassName="w-[500px] mx-auto"
      open={true}
    >
      <p className="text-lg leading-[28.8px] poppins">
        Are you sure you want to delete {name}? All of their data will be
        permanently removed.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          No, go back
        </Button>
        <Button
          className="px-8 text-[1rem] !bg-[#FDCDCD] !text-[#D30000]"
          onClick={handleDelete}
          loading={isLoading}
          variant="primary"
        >
          Yes delete
        </Button>
      </div>
    </PopupModal>
  );
}
