import { ReactComponent as FilterIcon } from "../../assets/icons/filters.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/more.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/chevron-left.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import UIPopover from "../../components/Popover";
// import UserDetails, { ConfirmBlock, ConfirmDelete } from "./UserDetails";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { ShowToast } from "../../components/toast";
import clsx from "clsx";
import { Button } from "../../components/Button";
import SearchComponent from "../../components/Search";
import ArchivedTopic from "./ArchivedTopic";
import { ConfirmBlock, ConfirmDelete } from "./components/Confirmations";
import { MultipleChipView } from "./components/MultipleChipView";
import { TopicForm } from "./TopicForm";

type Person = {
  id: string;
  name: string;
  email_id: string;
  date_created: string;
  publisher_data: any;
  last_active_date: string;
  thumb_image: string;
  isActive: boolean;
  course_levels?: any;
  likes_count?: any;
  course_count?: any;
  view_count?: any;
  lessons_count: number;
  user_name: string;
  sub_topics?: string[];
};

const columnHelper = createColumnHelper<Person>();

export function Topics() {
  const [sortBy, setSortBy] = useState<"name" | undefined>();
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | undefined>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = React.useState<any>(false);
  const [selectedCourse, setSelectedCourse] = React.useState<any | null>(null);
  const [search, setSearch] = React.useState("");
  const [tableData, setTableData] = React.useState<any[]>([]);
  const [isBlockedUsers, setIsBlockedUsers] = React.useState(false);
  const loadMoreRef = useRef<any>(null);
  const [filters, setFilters] = useState<{
    date: { startDate?: string; endDate?: string };
    types?: string[];
    selectedDateFilter?: string;
  }>();
  const [tempFilters, setTempFilters] = React.useState<{
    date: { startDate: string; endDate: string };
    types: string[];
    selectedDateFilter: string;
  }>({
    date: { startDate: "", endDate: "" },
    types: [],
    selectedDateFilter: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isTopicFormOpen, setIsTopicFormOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setIsUserDetailsOpen(false);
    setSelectedCourse(null);
  };

  const fetchContents = async ({ pageParam }) => {
    const res = await api.getTopics({
      page: pageParam,
      limit: 10,
      keyword: search,
    });
    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["topics", search, filters, sortBy, sortOrder],
    queryFn: fetchContents,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, pageParam) => {
      console.log(lastPage, "lastPage");
      if (lastPage?.data?.topic?.length < 10) return null;
      return pageParam + 1;
    },
  });

  console.log(data, "DDDDD");

  const columns = [
    columnHelper.accessor("name", {
      header: ({ column }) => {
        return (
          <button
            onClick={() => {
              if (sortBy === "name") {
                setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              } else {
                setSortBy("name");
                setSortOrder("ASC");
              }
            }}
            className="p-0 flex items-center gap-[2px]"
          >
            Topic name
            {sortBy === "name" ? (
              sortOrder === "ASC" ? (
                <ArrowDownIcon className="w-4 h-4" />
              ) : (
                <ArrowDownIcon className="w-4 h-4 rotate-[180deg]" />
              )
            ) : null}
          </button>
        );
      },
      cell: (info) => (
        <div className="flex flex-row items-center gap-2.5">
          <div className="flex flex-1 flex-col gap-0.5">
            <span>{info.row.original.name}</span>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.course_count || "-", {
      header: "# of courses",
    }),
    columnHelper.accessor((row) => row.view_count || "-", {
      header: "# of views",
    }),
    columnHelper.accessor("likes_count", {
      header: "# of likes",
      cell: (info) => info.getValue() || "-",
    }),
    columnHelper.accessor("sub_topics", {
      header: "Sub categories",
      cell: (info) => (
        <div>
          <MultipleChipView data={info.getValue() || []} />
        </div>
      ),
    }),
    columnHelper.accessor("date_created", {
      header: "Added on",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center">
          {moment(info.getValue()).format("MMM DD, YYYY") || "-"}
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="hover:!border-[#9D9D9D] hover:!bg-transparent !border-[#D7D7D7] !rounded-md h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center [&_circle]:fill-black"
              >
                <DotsIcon />
              </Button>
            }
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 !pr-8 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-12 mt-1 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    setSelectedCourse(info.row.original);
                    setIsUserDetailsOpen(info.row.original);
                  }}
                >
                  Edit
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedCourse(info.row.original);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<Person>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page?.data?.topic);
      // Set the combined data into the table data state
      console.log(newData, "newData");
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const hasFilters = useMemo(() => {
    return filters?.date.startDate || (filters?.types?.length || 0) > 0;
  }, [filters]);

  console.log(tableData, "tableData");

  return (
    <>
      <div className="flex flex-col gap-4">
        {!!isUserDetailsOpen && (
          <TopicForm
            onClose={handleClose}
            topic={selectedCourse}
            onUpdate={() => {
              refetch();
            }}
          />
        )}
        {isBlockedUsers && (
          <ArchivedTopic
            isOpen={isBlockedUsers}
            handleClose={() => setIsBlockedUsers(false)}
            refetchActiveUsers={refetch}
          />
        )}
        {isDeleteModalOpen && selectedCourse && (
          <ConfirmDelete
            onClose={() => setIsDeleteModalOpen(false)}
            userId={selectedCourse.topic_id}
            refetch={() => {
              refetch();
            }}
            name={`${selectedCourse.name}`}
          />
        )}

        <div className="flex flex-row justify-between">
          <h1 className="text-black text-2xl font-bold leading-[28.8px]">
            Manage Topics
          </h1>
        </div>
        <div className="bg-white p-5 flex flex-col">
          <div className="flex flex-row justify-between items-center mb-5">
            <div className="flex items-center gap-3">
              <SearchComponent
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                placeholder="Search"
                value={search}
              />
            </div>
            <div>
              <Button
                onClick={() => setIsTopicFormOpen(true)}
                variant="primary"
                className="!rounded-full"
              >
                + Add another topic
              </Button>
            </div>
          </div>

          <div
            className={`overflow-auto ${
              hasFilters ? "h-[calc(100vh-260px)]" : "h-[calc(100vh-190px)]"
            } relative flex flex-col`}
          >
            <table className="w-full relative">
              <colgroup>
                <col style={{ width: "25%" }} />
                <col style={{ width: "11%" }} />
                <col style={{ width: "11%" }} />
                <col style={{ width: "11%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "20%" }} />
              </colgroup>
              <thead className="w-full">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id} className="sticky top-0 z-[1]">
                    {headerGroup.headers.map((header, index) => (
                      <th
                        key={header.id}
                        className={clsx(
                          "text-[0.875rem] font-medium text-[#202020] text-start p-0"
                        )}
                      >
                        <div
                          className={clsx(
                            "w-full px-3 py-4 bg-[#F2F7F3] flex flex-row items-center gap-1 transition-all",
                            index === headerGroup.headers.length - 1 &&
                              "rounded-tr-[14px] rounded-br-[14px]",
                            index === 0 && "rounded-tl-[14px] rounded-bl-[14px]"
                          )}
                          // onClick={header.column.getToggleSortingHandler()}
                        >
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                          {/* {{
                          asc: <ArrowIcon />,
                          desc: <ArrowIcon className="rotate-[180deg]" />,
                        }[header.column.getIsSorted() as string] ?? null} */}
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {!isLoading &&
                  table.getRowModel().rows.map((row, index) => (
                    <tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className={clsx(
                            "px-3 py-4 border-b text-black/80 border-b-[#D7D7D7] text-[13px] text-left",
                            // last index no border
                            index === table.getRowModel().rows.length - 1 &&
                              "border-b-0"
                          )}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}

                {isFetching && (
                  <TableSkeleton
                    rows={5}
                    columns={table.getAllColumns().length}
                  />
                )}
              </tbody>
            </table>
            {!isLoading && !isFetching && tableData.length === 0 && (
              <div className="flex-1 flex justify-center items-center">
                <span className="text-[#999999] text-[0.875rem] font-medium">
                  No data available
                </span>
              </div>
            )}
            <div className="">
              <button
                onClick={() => fetchNextPage()}
                disabled={!hasNextPage || isFetchingNextPage}
                ref={loadMoreRef}
              >
                {/* {isFetchingNextPage
                ? "Loading more..."
                : hasNextPage
                ? "Load More"
                : "Nothing more to load"} */}
              </button>
            </div>
            {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
          </div>
        </div>
      </div>
      {isTopicFormOpen && (
        <TopicForm
          onClose={() => setIsTopicFormOpen(false)}
          onUpdate={(data) => {
            refetch();
          }}
        />
      )}
    </>
  );
}

export function TableSkeleton({
  rows,
  columns,
}: {
  rows: number;
  columns: number;
}) {
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <tr key={`skeleton-row-${rowIndex}`} className="animate-pulse">
          {Array.from({ length: columns }).map((_, colIndex) => (
            <td
              key={`skeleton-cell-${rowIndex}-${colIndex}`}
              className="px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]"
            >
              <div className="w-full h-4 bg-gray-300 rounded-md"></div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
