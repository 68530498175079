import { useState } from "react";
import { api } from "../../helpers/api";
import { ShowToast } from "../../components/toast";
import { Button } from "../../components/Button";
import { PopupModal } from "../../components/PopupModal";

export function ConfirmVideoDelete({
  onClose,
  videoId,
  refetch,
  name,
}: {
  onClose: () => void;
  videoId: string;
  refetch: () => void;
  name: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = () => {
    setIsLoading(true);
    api
      .deleteSubVideo(videoId)
      .then(() => {
        onClose();
        ShowToast({
          message: "Video deleted successfully",
          type: "success",
        });
        refetch && refetch();
        setIsLoading(false);
      })
      .catch(() => {
        ShowToast({
          message: "Failed to delete video",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <PopupModal
      onClose={onClose}
      contentClassName="w-[500px] mx-auto"
      open={true}
      showCloseButton={false}
    >
      <p className="text-lg leading-[28.8px] poppins">
        Are you sure you want to delete this video? All of its data will be
        permanently removed.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          No, go back
        </Button>
        <Button
          className="px-8 text-[1rem] !bg-[#FDCDCD] !text-[#D30000]"
          onClick={handleDelete}
          loading={isLoading}
          variant="primary"
        >
          Yes delete
        </Button>
      </div>
    </PopupModal>
  );
}

export function ConfirmDelete({
  onClose,
  userId,
  refetch,
  name,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
  name: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleDelete = () => {
    setIsLoading(true);
    api
      .deleteLesson(userId)
      .then(() => {
        onClose();
        ShowToast({
          message: "Lesson deleted successfully",
          type: "success",
        });
        refetch && refetch();
        setIsLoading(false);
      })
      .catch(() => {
        ShowToast({
          message: "Failed to delete lesson",
          type: "error",
        });
        setIsLoading(false);
      });
  };

  return (
    <PopupModal
      onClose={onClose}
      contentClassName="w-[500px] mx-auto"
      open={true}
      showCloseButton={false}
    >
      <p className="text-lg leading-[28.8px] poppins">
        Are you sure you want to delete {name}? All of its data will be
        permanently removed.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          No, go back
        </Button>
        <Button
          className="px-8 text-[1rem] !bg-[#FDCDCD] !text-[#D30000]"
          onClick={handleDelete}
          loading={isLoading}
          variant="primary"
        >
          Yes delete
        </Button>
      </div>
    </PopupModal>
  );
}

export function ConfirmBlock({
  onClose,
  userId,
  refetch,
  name,
}: {
  onClose: () => void;
  userId: string;
  refetch: () => void;
  name: string;
}) {
  const [blocking, setBlocking] = useState(false);
  const handleBlock = () => {
    setBlocking(true);
    api
      .archiveCourse(userId)
      .then(() => {
        setBlocking(false);
        onClose();
        ShowToast({
          message: "Lesson archived successfully",
          type: "success",
        });
        refetch && refetch();
      })
      .catch((err) => {
        setBlocking(false);
        ShowToast({
          message: err.response.data.message || `Failed to archive lesson`,
          type: "error",
        });
      });
  };

  return (
    <PopupModal
      onClose={onClose}
      contentClassName="w-[500px] mx-auto"
      open={true}
    >
      <p className="text-lg leading-[28.8px] poppins">
        Are you sure you want to deactivate {name}'s account? They will not be
        able to access their account until it is reactivated.
      </p>
      <div className="flex flex-row gap-4 ml-auto w-full">
        <Button
          variant="outline"
          className="px-8 flex-1"
          onClick={onClose}
          disabled={blocking}
        >
          No, go back
        </Button>
        <Button
          className="px-8 text-[1rem] flex-1 !bg-[#FDCDCD] !text-[#D30000]"
          onClick={handleBlock}
          loading={blocking}
          variant="primary"
        >
          Yes deactivate
        </Button>
      </div>
    </PopupModal>
  );
}
