import { Button } from "../../components/Button";
import { TextInput } from "../../components/TextInput";
import { Link } from "react-router-dom";
import React from "react";
import { ForgotPasswordSuccess } from "./components/ForgotPasswordSuccess";
import { Header } from "./components/Header";
import { ReactComponent as LeftArrow } from "../../assets/icons/arrow-left.svg";
import { ReactComponent as EmailIcon } from "../../assets/icons/email.svg";

import { ShowToast } from "../../components/toast";
import { api } from "../../helpers/api";
import { validateEmail } from "../../helpers/utils";

export function ForgotPassword() {
  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState<string>("");
  const [success, setSuccess] = React.useState(false);

  const handleSendEmail = async () => {
    if (email) {
      try {
        setLoading(true);
        const response = await api.forgotPassword({
          email,
        });
        if ([200, 201].includes(response.status)) {
          setSuccess(true);
        }
        setLoading(false);
      } catch (error) {
        ShowToast({
          type: "error",
          message: "Failed to send email",
        });
        setLoading(false);
      }
    }
  };

  // check if email is valid using regex
  const disabled = !email || !validateEmail(email);

  return (
    <div
      className="h-screen bg-secondary"
      style={{ backgroundImage: "url('/images/authpage-overlay.png')" }}
    >
      <Header showSignIn />
      <div className="flex flex-col">
        <div className="flex justify-center items-center min-h-[calc(100vh-150px)]">
          {success ? (
            <ForgotPasswordSuccess email={email} />
          ) : (
            <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col mx-3">
              <Link
                to={"/login"}
                className="flex items-center gap-1.5 text-base text-primary"
              >
                <LeftArrow />
                Back
              </Link>
              <div className="text-black text-2xl font-semibold leading-loose">
                Forgot your password?
              </div>
              <span className="text-[#666666] text-sm font-medium leading-normal ">
                Enter your register email below and we’ll send you a link to
                reset your password
              </span>
              <div className="my-6 flex flex-col gap-5">
                <div className="flex flex-col gap-1.5">
                  <span className="text-black text-xs font-medium">
                    Email address
                  </span>
                  <TextInput
                    placeholder="Your email"
                    className="w-full !p-[14px]"
                    name="email"
                    type="email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    icon={<EmailIcon />}
                  />
                </div>
              </div>
              <Button
                disabled={disabled}
                loading={loading}
                className="!rounded-full w-full"
                variant="primary"
                onClick={handleSendEmail}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
