import { Button } from "../../components/Button";
import { useSearchParams } from "react-router-dom";
import React from "react";
import { api } from "../../helpers/api";
import { Password } from "./components/Password";
import { Header } from "./components/Header";
import { ShowToast } from "../../components/toast";
import passwordValidation from "../../helpers/utils";
import { ResetPasswordSuccess } from "./components/ResetPasswordSuccess";
import { ReactComponent as LockIcon } from "../../assets/icons/lock.svg";

export function ResetPassword() {
  const [params] = useSearchParams();
  const resetPasswordToken = params.get("rt");
  const [disabled, setDisabled] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [isPasswordSame, setIsPasswordSame] = React.useState<string>("");
  const [errors, setErrors] = React.useState({} as any);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // get the form data
    const formData = new FormData(e.currentTarget);
    // convert the form data to an object
    const data = Object.fromEntries(formData);
    // log the results
    try {
      setLoading(true);
      const response = await api.resetPassword({
        resetPasswordToken,
        password: data.password,
      });
      if ([200, 201].includes(response.status)) {
        setSuccess(true);
      }
      setLoading(false);
    } catch (error: any) {
      ShowToast({
        type: "error",
        message:
          error?.response?.data?.message ||
          "There is some problem to reset password. Please try again",
      });
      setLoading(false);
    }
  };
  const handleChange = (e: React.ChangeEvent<HTMLFormElement>) => {
    const formData = new FormData(e.currentTarget);
    const data = Object.fromEntries(formData);
    if (
      e.target.name === "password" &&
      !passwordValidation(data.password.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        password:
          "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
      }));
    } else {
      setErrors((prev) => ({ ...prev, password: "" }));
    }
    if (
      e.target.name === "confirmPassword" &&
      !passwordValidation(data.confirmPassword.toString())
    ) {
      setErrors((prev) => ({
        ...prev,
        confirmPassword:
          "Password should contain at least 8 characters, 1 letter, 1 number and 1 special character",
      }));
    } else {
      setErrors((prev) => ({ ...prev, confirmPassword: "" }));
    }
    if (
      data.password &&
      data.confirmPassword &&
      data.password === data.confirmPassword &&
      passwordValidation(data.password.toString()) &&
      passwordValidation(data.confirmPassword.toString())
    ) {
      setIsPasswordSame("");
      setDisabled(false);
    } else {
      if (
        data.password &&
        data.confirmPassword &&
        data.password !== data.confirmPassword
      ) {
        setIsPasswordSame("Password does not match");
      } else {
        setIsPasswordSame("");
      }
      setDisabled(true);
    }
  };

  return (
    <div
      className="h-screen bg-secondary overflow-auto"
      style={{ backgroundImage: "url('/images/authpage-overlay.png')" }}
    >
      <Header showSignIn />
      <div className="flex flex-col">
        <div className="flex justify-center items-center min-h-[calc(100vh-150px)]">
          {success ? (
            <ResetPasswordSuccess />
          ) : (
            <form
              onSubmit={handleSubmit}
              className="flex flex-col"
              onChange={handleChange}
            >
              <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col mx-3">
                <div className="text-black text-[28px] font-bold leading-loose">
                  Reset Password
                </div>
                <span className="text-black/80 text-sm font-normal leading-normal ">
                  Create a new password
                </span>
                <div className="my-6 flex flex-col gap-5">
                  <div className="flex flex-col gap-1.5">
                    <span className="text-black text-xs font-medium">
                      Password
                    </span>
                    <Password
                      placeholder="Create a new password"
                      name="password"
                      icon={<LockIcon />}
                    />
                    {errors.password && (
                      <div className="text-red-500 text-xs font-medium">
                        {errors.password}
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col gap-1.5">
                    <span className="text-black text-xs font-medium">
                      Confirm Password
                    </span>
                    <Password
                      type="password"
                      placeholder="Re-enter the password"
                      name="confirmPassword"
                      icon={<LockIcon />}
                    />
                    {isPasswordSame && (
                      <span className="text-red-500 text-xs font-medium">
                        {isPasswordSame}
                      </span>
                    )}
                  </div>
                </div>
                <Button
                  disabled={disabled || loading}
                  loading={loading}
                  className="!rounded-full w-full"
                  variant="primary"
                >
                  Submit
                </Button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}
