import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import { Link } from "react-router-dom";
import { useState } from "react";
import UserCard, { UserCardSkeleton } from "./SubscriptionCardSkeletion";
import { DBUserType, Tabs, UserTabs } from "../users/UserTabs";
import moment from "moment";

const MAX_MESSAGE_LENGTH = 95;

function FeedbackMessage({ feedback }: { feedback: any }) {
  // show two lines of feedback message and then show ... if it is more than two lines
  // shows read more button if it is more than two lines
  // on click of read more button, show the full feedback message
  // on click of close button, hide the full feedback message and show the two lines again
  // also hide the read more button
  const [showFullMessage, setShowFullMessage] = useState(false);

  const messageLength = feedback.message.length;

  return (
    <div>
      <div>
        <div
          role="button"
          onClick={() => setShowFullMessage(!showFullMessage)}
          className="text-black text-sm font-normal font-['Montserrat'] leading-normal"
        >
          {showFullMessage
            ? feedback.message
            : `${feedback.message.slice(0, MAX_MESSAGE_LENGTH)}${
                messageLength > MAX_MESSAGE_LENGTH ? "..." : ""
              }`}
          {messageLength > MAX_MESSAGE_LENGTH && (
            <span className="text-[#355e3b] ml-1 text-sm font-semibold font-['Montserrat'] underline leading-normal">
              {showFullMessage ? "Read less" : "Read more"}
            </span>
          )}
        </div>
      </div>
    </div>
  );
}

export default function RecentSubscriptions({ onDelete, onView, onBlock }) {
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["feedbacks-dashboard"],
    queryFn: () =>
      api.getFeedbacks({
        page: 1,
        limit: 10,
      }),
  });

  console.log(data);
  return (
    <div className="flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)] h-[75dvh] flex flex-col !p-0 !gap-0">
      <div className="flex flex-row justify-between items-center border-b border-b-[#D7D7D7] p-6">
        <span className="text-xl leading-[113.6%] font-bold">
          New Feedbacks ({data?.data?.feedbackCount})
        </span>
        <Link
          to="/users"
          className="text-primary hover:opacity-50 font-medium leading-[113.6%] text-base h-fit"
        >
          View all
        </Link>
      </div>
      {!isLoading && (
        <div className="flex flex-col overflow-auto p-4 gap-3.5">
          {data?.data?.feedback?.map((feedback: any) => (
            <div className="w-full">
              <div className="w-full p-4 bg-white rounded-xl border border-[#e4ebe4] flex-col justify-start items-start gap-3.5 inline-flex">
                <FeedbackMessage
                  feedback={{
                    ...feedback,
                  }}
                />
                <div className="self-stretch p-1.5 bg-[#e4ebe4]/60 rounded-[7px] justify-start items-center gap-2.5 flex">
                  <img
                    className="w-8 h-8 rounded-[5px] border border-[#355e3b]/30"
                    src={feedback?.video_data?.thumb_image}
                    alt="thumbnail"
                  />
                  <div className="text-[#444444] text-sm font-medium font-['Montserrat'] leading-normal">
                    {feedback?.video_data?.title}
                  </div>
                </div>
                <div>
                  <span className="text-[#444444] text-[13px] font-medium font-['Montserrat'] leading-none">
                    by{" "}
                  </span>
                  <span className="text-[#355e3b] text-[13px] font-semibold font-['Montserrat'] underline leading-none">
                    {feedback?.user_data?.first_name}{" "}
                    {feedback?.user_data?.last_name}
                  </span>
                  <span className="text-[#444444] text-[13px] font-medium font-['Montserrat'] leading-none">
                    {" "}
                    on {moment(feedback?.created_at).format(
                      "MMM DD, YYYY"
                    )} at {moment(feedback?.created_at).format("hh:mm A")}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      {isLoading && (
        <div className="flex flex-col overflow-auto">
          <UserCardSkeleton />
          <UserCardSkeleton />
          <UserCardSkeleton />
        </div>
      )}
    </div>
  );
}
