import { platform } from "os";
import client from "./client";

export const api = {
  login: (data: any) => {
    return client.post("/admin/login", {
      ...data,
    });
  },
  forgotPassword: (data: any) => {
    return client.post("/admin/forgot-password", data);
  },
  resetPassword: (data: any) => {
    return client.post("/admin/forgot-password/update", data);
  },
  getFileUpload: (data: {
    folder: string;
    fileName: string;
    contentType: string;
  }) => {
    return client.post("/general/sign-url/get", data).then((res) => res.data);
  },
  dashboard: (id: string) => {
    return client.get(`/users/${id}/dashboard`).then((res) => res.data);
  },
  getFeedbacks: ({ page, limit }: { page: number; limit: number }) => {
    return client
      .get(`/admin/dashboard/feedback/get?page=${page}&limit=${limit}`)
      .then((res) => res.data);
  },
  getUser: ({ id }: { id: string }) => {
    return client.get(`/admin/user-details/get/${id}`);
  },
  getUserLikedCourses: ({
    id,
    page,
    limit,
  }: {
    id: string;
    page: number;
    limit: number;
  }) => {
    return client
      .get(`/admin/user-likes/get/${id}?page=${page}&limit=${limit}`)
      .then((res) => res.data);
  },
  getUserSavedCourses: ({
    id,
    page,
    limit,
  }: {
    id: string;
    page: number;
    limit: number;
  }) => {
    return client
      .get(`/admin/user-saved/get/${id}?page=${page}&limit=${limit}`)
      .then((res) => res.data);
  },
  dashboardCounts: () => {
    return client.get(`/admin/dashboard/count/get`).then((res) => res.data);
  },
  // /users/{id}/reports
  getUserRevenue: ({
    id,
    type,
    duration,
  }: {
    id: string;
    type: "USERS_COUNT" | "REVENUE";
    duration: "this_week" | "this_month" | "this_year";
  }) => {
    return client
      .get(`/admin/dashboard/graph-count/get`, {
        params: {
          filter: duration,
        },
      })
      .then((res) => res.data);
  },
  getOrganisations: ({
    page,
    limit,
    keyword,
    platform,
    type,
    showActive = true,
    sortBy,
    sortOrder,
  }: {
    page?: number;
    limit?: number;
    keyword?: string;
    platform?: string[];
    type?: string;
    showActive?: boolean;
    sortBy?: string;
    sortOrder?: string;
  }) => {
    let params = {
      page,
      limit,
    };

    params["showActive"] = showActive;
    if (keyword) params["keyword"] = keyword;
    if (type) params["type"] = type;

    if (sortBy) {
      params["sortBy"] = sortBy;
    }
    if (sortOrder) {
      params["sortOrder"] = sortOrder;
    }
    if (platform && platform?.length > 0)
      params["platform"] = platform.join(",");

    // Serialize params to query string
    const queryString = Object.entries(params)
      .map(([key, value]: any) => `${key}=${encodeURIComponent(value)}`)
      .join("&");

    return client.get(`/organisations?${queryString}`).then((res) => res.data);
  },
  createOrganisation: (data: any) => {
    return client.post("/organisations", data);
  },
  updateOrganisation: (id: string, data: any) => {
    return client.patch(`/organisations/${id}`, data);
  },
  updateOrganizationStatus: (id: string, isActive: boolean) => {
    return client.patch(`/organisations/${id}/status`, {
      isActive,
    });
  },
  approveOrganization: (id: string) => {
    return client.patch(`/organisations/${id}/request/approve`);
  },
  declineOrganization: (id: string) => {
    return client.patch(`/organisations/${id}/request/decline`);
  },
  deleteOrganization: (id: string) => {
    return client.delete(`/organisations/${id}`);
  },
  deleteOrganizationRequest: (id: string) => {
    return client.delete(`/organisations/${id}/request`);
  },
  getUsers: (
    {
      page,
      limit,
      keyword,
      status,
      filters,
      type,
      sortBy,
      sortOrder,
    }: {
      page: number;
      limit: number;
      keyword?: string;
      status: string;
      filters?: any;
      type?: string;
      sortBy?: string;
      sortOrder?: string;
    },
    signal?: any
  ) => {
    let url = `/admin/user-list/get?page=${page}&limit=${limit}&status=${status}`;

    if (keyword) {
      url += `&keyword=${keyword}`;
    }

    if (sortBy) {
      url += `&sortBy=${sortBy}`;
    }
    if (sortOrder) {
      url += `&sortOrder=${sortOrder}`;
    }
    if (filters?.types && filters?.types?.length > 0) {
      const types = filters.types.join(",");
      if (types?.length > 0) {
        url += `&tiers=${filters.types.join(",")}`;
      }
    }
    return client
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  updateUser: (id: string, isActive: boolean) => {
    return client.patch(`/users/${id}/status`, {
      isActive,
    });
  },
  deleteUser: (id: string) => {
    return client.delete(`/admin/user/remove/${id}`);
  },
  manageBlock: (id: string) => {
    return client.post(`/admin/user/manage-block`, {
      user_id: id,
    });
  },
  getPosts: (
    {
      page,
      limit,
      userId,
      type,
    }: {
      page: number;
      limit: number;
      userId: string;
      type?: string;
    },
    signal?: any
  ) => {
    console.log("fetching posts", userId);
    let url = `/posts?userId=${userId}&page=${page}&limit=${limit}`;
    return client
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  getLikedPosts: (
    {
      userId,
      page,
      limit,
    }: {
      userId: string;
      page: number;
      limit: number;
    },
    signal?: any
  ) => {
    let url = `/posts/fetch/liked-posts?userId=${userId}&page=${page}&limit=${limit}`;
    return client
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  getSavedPosts: (
    {
      userId,
      page,
      limit,
    }: {
      userId: string;
      page: number;
      limit: number;
    },
    signal?: any
  ) => {
    let url = `/posts/fetch/bookmarked-posts?userId=${userId}&page=${page}&limit=${limit}`;
    return client
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  deletePost: (id: string) => {
    return client.delete(`/posts/${id}`);
  },
  getStylists: (id: string) => {
    return client.get(`/users/${id}/get-stylists`).then((res) => res.data);
  },
  getReviews: (
    {
      page,
      limit,
      userId,
      othersReviews,
    }: {
      page: number;
      limit: number;
      userId: string;
      othersReviews: boolean;
    },
    signal?: any
  ) => {
    console.log("fetching posts", userId);
    let url = `/users/${userId}/reviews?page=${page}&limit=${limit}`;
    if (othersReviews) {
      url += `&othersReviews=true`;
    }
    return client
      .get(url, {
        signal,
      })
      .then((res) => res.data);
  },
  deleteLesson: (id: string) => {
    return client.delete(`/admin/publisher-video/delete/${id}`);
  },
  getContents: ({
    page,
    limit,
    keyword,
    type,
  }: {
    page: number;
    limit: number;
    keyword?: string;
    type?: string;
  }) => {
    let url = `/admin/publisher-video/get-list?page=${page}&limit=${limit}`;
    if (keyword) url += `&keyword=${keyword}`;
    if (type) url += `&type=${type}`;
    return client.get(url).then((res) => res.data);
  },
  getCourseLevels: () => {
    return client
      .get("/course-level/get?page=1&limit=10000")
      .then((res) => res.data);
  },
  archiveCourse: (id: string) => {
    console.log("archiving course", id);
    return client.post("/admin/publisher-video/manage-archive", {
      video_id: id,
    });
  },
  getCourseDetails: (id: string) => {
    return client
      .get(`/admin/publisher-video/get/${id}`)
      .then((res) => res.data);
  },
  saveBulkSubVideos: (data: any) => {
    return client.post("/admin/sub-video/bulk-save", data);
  },
  saveSubVideo: (data: any) => {
    return client.post("/admin/sub-video/save", data);
  },
  updateSubVideo: (id: string, data: any) => {
    return client.put(`/admin/sub-video/update/${id}`, data);
  },
  deleteSubVideo: (id: string) => {
    return client.delete(`/admin/sub-video/delete/${id}`);
  },
  sortSubVideos: (data: any) => {
    return client.post("/admin/sub-video/sort-priority", data);
  },
  createTopic: (data: any) => {
    return client.post("/admin/topic/save", data);
  },
  updateTopic: (id: string, data: any) => {
    return client.put(`/admin/topic/update/${id}`, data);
  },
  deleteTopic: (id: string) => {
    return client.delete(`/admin/topic/delete/${id}`);
  },
  getTopics: ({
    page,
    limit,
    keyword,
  }: {
    page: number;
    limit: number;
    keyword?: string;
  }) => {
    let url = `/admin/topic/get-list?page=${page}&limit=${limit}`;
    if (keyword) url += `&keyword=${keyword}`;
    return client.get(url).then((res) => res.data);
  },
  createPublisherVideo: (data: any) => {
    return client.post("/admin/publisher-video/save", data);
  },
};
