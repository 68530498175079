import UIPopover from "../../../components/Popover";

function Chip({ text, className }: { text: string; className?: string }) {
  return (
    <div
      className={`h-[21px] w-fit px-2 py-[3px] text-black/80 text-xs font-normal font-['Montserrat'] bg-neutral-100 rounded justify-center items-center gap-2.5 inline-flex ${className}`}
    >
      {text}
    </div>
  );
}

export function MultipleChipView({
  data,
  itemsToShow = 2,
  fieldName,
  isOutline,
}: {
  data: any[];
  itemsToShow?: number;
  fieldName?: string;
  isOutline?: boolean;
}) {
  const itemsList = data.slice(0, itemsToShow);
  const remainingItemList = data.slice(itemsToShow);
  return (
    <div className="flex items-center gap-2 [&_.chip]:!h-[23px] [&_.popover-open]:!bg-[#F5F5F5]">
      {itemsList.map((item, index) => (
        <Chip key={index} text={fieldName ? item?.[fieldName] : item} />
      ))}
      {remainingItemList.length > 0 && (
        <UIPopover
          trigger={
            <div className="h-[23px] p-[7px] text-[#707070] text-[13px] font-medium rounded border border-[#ebebeb] justify-center items-center gap-0.5 flex">
              +{remainingItemList.length}
            </div>
          }
          positions={["right", "left", "top", "bottom"]}
        >
          {(close) => (
            <Dropdown
              data={remainingItemList}
              fieldName={fieldName}
              isOutline={isOutline}
            />
          )}
        </UIPopover>
      )}
    </div>
  );
}

const Dropdown = ({
  data = [],
  fieldName,
  isOutline,
}: {
  data: any[];
  fieldName?: string;
  isOutline?: boolean;
}) => {
  return (
    <div
      className={`z-10 w-fit min-w-[145px] max-h-[200px] overflow-auto ml-2.5 bg-white shadow-[0px_4px_16px_rgba(0,0,0,0.13)] rounded-[12px] p-3 flex flex-col gap-2.5 ${
        isOutline
          ? "[&_.chip]:!text-[#707070] [&_.chip]:!border [&_.chip]:!border-[#ebebeb] [&_.chip]:!bg-transparent [&_.chip]:!h-[23px]"
          : ""
      }`}
    >
      {data?.map((item, index) => (
        <Chip key={index} text={fieldName ? item?.[fieldName] : item} />
      ))}
    </div>
  );
};
