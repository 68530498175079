import { useNavigate } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { Button } from "../../../components/Button";
import toast from "react-hot-toast";
import { api } from "../../../helpers/api";
import { ShowToast } from "../../../components/toast";
import React from "react";

export function ForgotPasswordSuccess({ email }: { email: string }) {
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();
  const handleLoginClick = () => {
    navigate("/login");
  };
  const handleResend = async () => {
    console.log("resend");
    if (email) {
      try {
        toast.loading("Sending email...");
        setLoading(true);
        const response = await api.forgotPassword({
          email,
        });
        toast.dismiss();
        setLoading(false);
        if ([200, 201].includes(response.status)) {
          ShowToast({
            type: "success",
            message: "Email sent successfully",
          });
        }
      } catch (error) {
        toast.dismiss();
        setLoading(false);
        ShowToast({
          type: "error",
          message: "Failed to send email",
        });
      }
    }
  };
  return (
    <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col items-center mx-3">
      {/* <SuccessIcon /> */}
      <div className="rounded-full border-8 border-[#b6efc7]/25">
        <div className="w-14 h-14 bg-[#B6EFC7] justify-center items-center gap-2.5 flex rounded-full">
          <CheckIcon className="w-[22px] h-[22px]" />
        </div>
      </div>
      <div className="text-black text-[28px] font-semibold leading-loose text-center mt-1 mb-4">
        Reset password link sent!
      </div>
      <span className="text-black/80 text-sm font-normal text-center leading-normal ">
        Please check your email. We've sent a reset password link to your email
        '{email}'.
      </span>

      <Button
        className="!rounded-full w-fit mt-8"
        variant="primary"
        onClick={handleLoginClick}
      >
        Go to login
      </Button>
      <div className="text-center mt-6">
        <span className="text-[#666666] text-base font-medium font-['Montserrat'] leading-relaxed">
          Didn’t receive a link?{" "}
        </span>
        <button
          onClick={handleResend}
          disabled={loading}
          className="text-[#355e3b] text-base font-semibold font-['Montserrat'] leading-relaxed"
        >
          Resend
        </button>
      </div>
    </div>
  );
}
