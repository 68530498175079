import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";

export function PopupModal({
  children,
  onClose,
  open,
  title = "",
  contentClassName,
  showCloseButton = true,
  modalClassName = "",
}: {
  children: any;
  onClose: () => void;
  open: boolean;
  contentClassName?: string;
  showCloseButton?: boolean;
  modalClassName?: string;
  title?: string;
}) {
  return (
    <div
      className={`fixed inset-0 z-50 overflow-y-auto backdrop-blur-sm ${modalClassName} ${
        open ? "block" : "hidden"
      }`}
      onClick={onClose}
    >
      <div className="flex items-center justify-center min-h-screen relative">
        <div className="fixed inset-0 bg-black bg-opacity-40" />
        <div
          className={`relative bg-white z-[1] rounded-[16px] max-w-[665px] w-full mx-6 md:mx-auto px-7 pb-7 pt-6 flex flex-col ${contentClassName}`}
          onClick={(e) => e.stopPropagation()}
        >
          {title && (
            <div className="header flex items-center justify-between mb-5">
              <h1 className="text-black text-[32px] font-bold">{title}</h1>
              {showCloseButton && (
                <button onClick={onClose} aria-label="Close">
                  <CloseIcon className="w-6 h-6 close" />
                </button>
              )}
            </div>
          )}
          {children}
        </div>
      </div>
    </div>
  );
}
