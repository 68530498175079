import { Routes, Route, Navigate } from "react-router-dom";
import { Main } from "./main";
import { Login } from "./pages/auth/login/Login";
import Dashboard from "./pages/dashboard/Dashboard";
import Users from "./pages/users/Users";
import { Topics } from "./pages/topics/Topics";
import { Contents } from "./pages/content/Contents";
import { ForgotPassword } from "./pages/auth/ForgotPassword";
import { ResetPassword } from "./pages/auth/ResetPassword";

const RoutesList = () => {
  return (
    <Routes>
      <Route>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
      </Route>
      <Route path="/" element={<Main />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="users" element={<Users />} />
        <Route path="topics" element={<Topics />} />
        <Route path="contents" element={<Contents />} />
        <Route path="*" element={<Navigate to="/dashboard" />} />
      </Route>
    </Routes>
  );
};
export default RoutesList;
