import { ReactComponent as UpIcon } from "../../assets/icons/up.svg";
import { ReactComponent as ChevronIcon } from "../../assets/icons/chevron-down.svg";
import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";
import UIPopover from "../../components/Popover";
import { useQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import { useState } from "react";
import moment from "moment";
import clsx from "clsx";
import { useUser } from "../auth/context/user-context";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} fontSize={12} textAnchor="end" fill="#666">
        {rest.type === "this_week"
          ? moment(payload.value).format("dd").substring(0, 1)
          : rest.type === "this_month"
          ? moment(payload.value).format("DD")
          : // turn month number to month name
          payload.value >= 1 && payload.value <= 12
          ? months[payload.value - 1]
          : payload.value}
      </text>
    </g>
  );
}

const CustomTooltip = ({ active, payload, label }: any) => {
  if (active && payload && payload.length) {
    return (
      <div className="flex flex-col gap-1 items-center justify-center px-4 py-5 !shadow-[0px_16px_40px_0px_rgba(2,96,0,0.11)] rounded-[11px] bg-white">
        <p className="leading-[18px] text-base">
          {payload[0]?.payload?.date
            ? moment(payload[0]?.payload?.date).format("MMM DD, YYYY")
            : moment()
                .month(payload[0]?.payload?.month - 1)
                .format("MMM, YYYY")}
        </p>
        <p className="text-[22px] font-bold leading-[24px]">
          {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};
export default function Chart() {
  const [type, setType] = useState<"REVENUE" | "USERS_COUNT">("USERS_COUNT");
  const [duration, setDuration] = useState<
    "this_week" | "this_month" | "this_year"
  >("this_week");
  const { userInfo: user } = useUser() as any;

  const { data, isLoading } = useQuery({
    queryKey: ["chart", user?.id, type, duration],
    queryFn: () =>
      api.getUserRevenue({
        id: user!.id,
        type: type,
        duration: duration,
      }),
    enabled: !!user,
  });

  console.log(data);
  return (
    <div className="flex-1 flex gap-4 p-6 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)] h-[75dvh] flex-col">
      <div className="flex flex-row justify-between">
        <div className="flex flex-row gap-[1.25rem]">
          {/* <button
            className={clsx("text-xl leading-[113.6%] font-bold", {
              "opacity-40": type !== "REVENUE",
            })}
            onClick={() => setType("REVENUE")}
          >
            Revenue
          </button> */}
          <button
            className={clsx("text-xl leading-[113.6%] font-bold", {
              "opacity-40": type !== "USERS_COUNT",
            })}
            onClick={() => setType("USERS_COUNT")}
          >
            Users
          </button>
        </div>
        <UIPopover
          trigger={
            <div className="px-4 py-2 rounded-[20px] bg-[#F3F3F3] text-[1rem] leading-[113.6%] flex flex-row gap-1 items-center">
              {duration === "this_week" && "This Week"}
              {duration === "this_month" && "This Month"}
              {duration === "this_year" && "This Year"}
              <ChevronIcon className=" [&_path]:stroke-black" />
            </div>
          }
        >
          {(close) => (
            <div className="flex flex-col gap-4 bg-white p-4 shadow-lg rounded-lg">
              <button
                onClick={() => {
                  close();
                  setDuration("this_week");
                }}
                className="text-start font-inter"
              >
                This Week
              </button>
              <button
                onClick={() => {
                  close();
                  setDuration("this_month");
                }}
                className="text-start font-inter"
              >
                This Month
              </button>
              <button
                onClick={() => {
                  close();
                  setDuration("this_year");
                }}
                className="text-start font-inter"
              >
                This Year
              </button>
            </div>
          )}
        </UIPopover>
      </div>
      <div className="flex flex-row justify-between rounded-[8px] border border-[#E8E8E8] p-4 my-1 mb-8">
        <span>
          {moment(
            data?.data?.graphData?.[0]?.date ||
              data?.data?.graphData?.[0]?.month
          ).format("MMM DD")}{" "}
          -{" "}
          {moment(
            data?.data?.graphData?.[data?.data?.graphData?.length - 1]?.date ||
              data?.data?.graphData?.[data?.data?.graphData?.length - 1]?.month
          ).format("MMM DD")}
        </span>

        <div className="flex flex-row gap-1 items-center">
          <span>
            {type === "REVENUE" ? "$" : ""}
            {data?.data?.graphData?.reduce(
              (acc: number, curr: any) => acc + curr.count,
              0
            ) ||
              data?.data?.graphData?.reduce(
                (acc: number, curr: any) => acc + curr.count,
                0
              ) ||
              0}
          </span>
          <div className="w-5 h-5 flex justify-center items-center bg-[#DEF6E3] rounded-full">
            <UpIcon className="w-3.5 h-3.5" />
          </div>
        </div>
      </div>
      {!isLoading && (
        <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            data={
              type === "REVENUE"
                ? data?.data?.graphData?.map((item) => ({
                    ...item,
                    count: item.count,
                    name: item.date || item.month,
                  }))
                : data?.data?.graphData?.map((item) => ({
                    ...item,
                    count: item.count,
                    name: item.date || item.month,
                  }))
            }
            margin={{
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient
                id="paint0_linear_53_758"
                x1="260.598"
                y1="-64.3191"
                x2="271.53"
                y2="378.817"
                gradientUnits="userSpaceOnUse"
              >
                {/* <stop stop-color="#2D4EFF" stopOpacity="1" />
                <stop offset="1" stop-color="#4aa06426" stop-opacity="0" /> */}
                <stop stop-color="#4AA064" />
                <stop offset="1" stop-color="#4AA064" stop-opacity="0" />
              </linearGradient>
            </defs>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="name"
              tick={(props) => <CustomizedLabel {...props} type={duration} />}
              interval={0}
            />
            <YAxis
              tickFormatter={(tick) => (tick === -10 ? "" : tick)} // Hide the tick at -100
              className="font-[500] text-xs"
              axisLine={false}
              tickLine={false}
              domain={[0, "dataMax"]}
              tickCount={10}
            />
            <Tooltip content={<CustomTooltip />} />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="count"
              stroke="#4AA064"
              strokeWidth="3px"
              fill="url(#paint0_linear_53_758)"
            />
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}
