import { ReactComponent as DotsIcon } from "../../assets/icons/more.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import UIPopover from "../../components/Popover";
import { useInfiniteQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { TableSkeleton } from "./Users";
import FullScreenModal from "../../components/FullScreenModal";
import UserIcon from "../../assets/icons/profile-icon.svg";
import { ShowToast } from "../../components/toast";
import clsx from "clsx";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import { ConfirmDelete } from "./UserDetails";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import { Button } from "../../components/Button";
import toast from "react-hot-toast";

type Person = {
  user_id: string;
  first_name: string;
  last_name: string;
  email_id: string;
  date_created: string;
  tier: string;
  last_active_date: string;
  user_image: string;
  isActive: boolean;
  mobile?: string;
  lessons_viewed: number;
  user_name: string;
};

const columnHelper = createColumnHelper<Person>();

export default function InactiveUsers({
  isOpen,
  handleClose,
  refetchActiveUsers,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetchActiveUsers: () => void;
}) {
  const [tableData, setTableData] = useState<Person[] | null>(null);
  const loadMoreRef = useRef<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userRowSelected, setUserRowSelected] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>([]);

  const fetchUsers = async ({ pageParam }) => {
    const res = await api.getUsers({
      page: pageParam,
      limit: 10,
      status: "inactive",
    });
    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["inactive-users", isOpen],
    queryFn: fetchUsers,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, pageParam) => {
      // console.log(lastPage);
      if (lastPage?.data?.user?.length < 10) return null;
      return pageParam + 1;
    },
  });

  const columns = [
    columnHelper.accessor("first_name", {
      header: ({ column }) => {
        return (
          <button className="p-0 flex items-center gap-[2px]">Full name</button>
        );
      },
      cell: (info) => (
        <div className="flex flex-row items-center gap-2.5">
          <img
            src={info.row.original.user_image || UserIcon}
            alt="user"
            className="w-10 h-10 rounded-full object-fill"
          />
          <div className="flex flex-col gap-0.5">
            <span>
              {info.row.original.first_name} {info.row.original.last_name}
            </span>
            <div className="text-[13px] text-black/40 poppins font-medium">
              @{info.row.original?.user_name}
            </div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.email_id, {
      header: "Email",
    }),
    columnHelper.accessor((row) => row.mobile, {
      header: "Phone",
    }),
    columnHelper.accessor("lessons_viewed", {
      header: "# of lessons viewed",
    }),
    columnHelper.accessor("date_created", {
      header: "Joined on",
      cell: (info) => moment(info.getValue()).format("MMM DD, YYYY") || "-",
    }),
    columnHelper.accessor("last_active_date", {
      header: "Last activity",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center gap-6">
          {info.getValue()
            ? moment(info.getValue()).local().format("MMM DD, YYYY | hh:mmA")
            : "-"}
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="hover:!border-[#9D9D9D] hover:!bg-transparent !border-[#D7D7D7] !rounded-md h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center [&_circle]:fill-black"
              >
                <DotsIcon />
              </Button>
            }
            containerClassName="z-[50]"
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    toast.loading("Unblocking user...");
                    api
                      .manageBlock(info.row.original.user_id)
                      .then(() => {
                        toast.dismiss();
                        close();
                        ShowToast({
                          message: "User activated successfully",
                          type: "success",
                        });
                        refetch();
                        refetchActiveUsers();
                      })
                      .catch((e: any) => {
                        toast.dismiss();
                        ShowToast({
                          message:
                            e.response.data.message ||
                            "Failed to activate user",
                          type: "error",
                        });
                      });
                  }}
                >
                  Re-activate user
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() => {
                    setUserRowSelected(info.row.original);
                    setIsDeleteModalOpen(true);
                    close();
                  }}
                >
                  Delete user
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<Person>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page?.data?.user);
      // Set the combined data into the table data state
      console.log(newData, "newData");
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <FullScreenModal isOpen={isOpen} onClose={handleClose}>
      {isDeleteModalOpen && (
        <ConfirmDelete
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          userId={userRowSelected?.id}
          refetch={refetch}
          name={userRowSelected?.fullName}
        />
      )}
      <div className="overflow-auto h-full relative max-w-[1000px] mx-auto">
        <h1 className="text-2xl font-inter font-medium mb-5 mt-5">
          Inactive Users
        </h1>
        <div className="!h-[calc(100%-120px)] overflow-auto">
          <table className="w-full relative">
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "18%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "20%" }} />
            </colgroup>
            <thead className="w-full">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="sticky top-0">
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={clsx(
                        "text-[0.875rem] font-medium text-[#202020] text-start p-0"
                      )}
                    >
                      <div
                        className={clsx(
                          "w-full px-3 py-4 bg-[#F2F7F3] flex flex-row items-center gap-1 transition-all",
                          index === headerGroup.headers.length - 1 &&
                            "rounded-tr-[14px] rounded-br-[14px]",
                          index === 0 && "rounded-tl-[14px] rounded-bl-[14px]"
                        )}
                        // onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {/* {{
                          asc: <ArrowIcon />,
                          desc: <ArrowIcon className="rotate-[180deg]" />,
                        }[header.column.getIsSorted() as string] ?? null} */}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {!isLoading &&
                table.getRowModel().rows.map((row, index) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={clsx(
                          "px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]",
                          // last index no border
                          index === table.getRowModel().rows.length - 1 &&
                            "border-b-0"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              {isFetching && (
                <TableSkeleton
                  rows={5}
                  columns={table.getAllColumns().length}
                />
              )}
            </tbody>
          </table>
          {tableData?.length === 0 && !isFetching && (
            <div className="h-[calc(100%-120px)] flex flex-col justify-center items-center w-full text-sm">
              No inactive users found
            </div>
          )}
        </div>

        <div className="">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          >
            {/* {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"} */}
          </button>
        </div>
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </div>
    </FullScreenModal>
  );
}
