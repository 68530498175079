import { ReactComponent as DotsIcon } from "../../assets/icons/more.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useMemo, useRef, useState } from "react";
import UIPopover from "../../components/Popover";
import { useInfiniteQuery } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { TableSkeleton } from "../users/Users";
import FullScreenModal from "../../components/FullScreenModal";
import UserIcon from "../../assets/icons/profile-icon.svg";
import { ShowToast } from "../../components/toast";
import clsx from "clsx";
import { ReactComponent as ArrowIcon } from "../../assets/icons/arrow-down.svg";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import { Button } from "../../components/Button";
import { ConfirmDelete } from "./Confirmations";
import React from "react";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/chevron-left.svg";
import toast from "react-hot-toast";

type Person = {
  id: string;
  title: string;
  email_id: string;
  date_created: string;
  publisher_data: any;
  last_active_date: string;
  thumb_image: string;
  isActive: boolean;
  course_levels?: any;
  lessons_count: number;
  user_name: string;
  video_id: string;
};

const columnHelper = createColumnHelper<Person>();

export default function ArchivedContent({
  isOpen,
  handleClose,
  refetch: refresh,
  onDelete,
}: {
  isOpen: boolean;
  handleClose: () => void;
  refetch: () => void;
  onDelete: (course: Person) => void;
}) {
  const [sortBy, setSortBy] = useState<"title" | undefined>();
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | undefined>();
  const [tableData, setTableData] = useState<Person[] | null>(null);
  const loadMoreRef = useRef<any>(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const [isCourseFormOpen, setIsCourseFormOpen] = useState(false);
  const [userRowSelected, setUserRowSelected] = useState<any>();
  const [sorting, setSorting] = useState<SortingState>([]);
  const [selectedCourse, setSelectedCourse] = React.useState<any | null>(null);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = React.useState<any>(false);

  const fetchUsers = async ({ pageParam }) => {
    const res = await api.getContents({
      page: pageParam,
      limit: 10,
      type: "archive",
    });
    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["archived-content", isOpen],
    queryFn: fetchUsers,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, pageParam) => {
      // console.log(lastPage);
      if (lastPage?.data?.publisherVideo?.length < 10) return null;
      return pageParam + 1;
    },
  });

  const columns = [
    columnHelper.accessor("title", {
      header: ({ column }) => {
        return (
          <button
            onClick={() => {
              if (sortBy === "title") {
                setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              } else {
                setSortBy("title");
                setSortOrder("ASC");
              }
            }}
            className="p-0 flex items-center gap-[2px]"
          >
            Course name
            {sortBy === "title" ? (
              sortOrder === "ASC" ? (
                <ArrowDownIcon className="w-4 h-4" />
              ) : (
                <ArrowDownIcon className="w-4 h-4 rotate-[180deg]" />
              )
            ) : null}
          </button>
        );
      },
      cell: (info) => (
        <div className="flex flex-row items-center gap-2.5">
          <img
            src={info.row.original.thumb_image || UserIcon}
            alt="user"
            className="w-10 h-10 rounded-[3px] opacity-80 object-fill"
          />
          <div className="flex flex-1 flex-col gap-0.5">
            <span>{info.row.original.title}</span>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.lessons_count, {
      header: "# of lessons",
    }),
    columnHelper.accessor((row) => row.course_levels, {
      header: "Course level",
    }),
    columnHelper.accessor("date_created", {
      header: "Created on",
      cell: (info) => moment(info.getValue()).format("MMM DD, YYYY") || "-",
    }),
    columnHelper.accessor("publisher_data", {
      header: "Course by",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center">
          <div className="flex flex-row gap-2 items-center">
            <img
              src={info.row.original.publisher_data.publisher_image}
              alt="publisher"
              className="w-[35px] h-[35px] rounded object-fill"
            />
            <span className="text-black text-[13px] font-medium">
              {info.row.original.publisher_data.full_name}
            </span>
          </div>
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="hover:!border-[#9D9D9D] hover:!bg-transparent !border-[#D7D7D7] !rounded-md h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center [&_circle]:fill-black"
              >
                <DotsIcon />
              </Button>
            }
            containerClassName="z-[1000]"
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 !pr-8 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-12 mt-1 text-sm">
                {/* <button
                  className="text-start"
                  onClick={() => {
                    setSelectedCourse(info.row.original);
                    setIsUserDetailsOpen(info.row.original);
                  }}
                >
                  View
                </button> */}
                <button
                  className="text-start"
                  onClick={() => {
                    toast.loading("Unarchiving...");
                    api
                      .archiveCourse(info.row.original.video_id)
                      .then(() => {
                        refetch();
                        refresh();
                        toast.dismiss();
                        ShowToast({
                          message: "Unarchived successfully",
                          type: "success",
                        });
                      })
                      .catch(() => {
                        toast.dismiss();
                        ShowToast({
                          message: "Failed to unarchive",
                          type: "error",
                        });
                      });
                  }}
                >
                  Unarchive
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() => {
                    onDelete(info.row.original);
                  }}
                >
                  Delete
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<Person>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page?.data?.publisherVideo);
      // Set the combined data into the table data state
      console.log(newData, "newData");
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  return (
    <FullScreenModal isOpen={isOpen} onClose={handleClose}>
      {isDeleteModalOpen && (
        <ConfirmDelete
          onClose={() => {
            setIsDeleteModalOpen(false);
          }}
          userId={userRowSelected?.id}
          refetch={refetch}
          name={userRowSelected?.fullName}
        />
      )}
      <div className="overflow-auto h-full relative max-w-[1000px] mx-auto">
        <h1 className="text-2xl font-inter font-medium mb-5 mt-5">
          Archived content
        </h1>
        <div className="!h-[calc(100%-120px)] overflow-auto">
          <table className="w-full relative">
            <colgroup>
              <col style={{ width: "25%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "15%" }} />
              <col style={{ width: "25%" }} />
            </colgroup>
            <thead className="w-full">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="sticky top-0 z-[1]">
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={clsx(
                        "text-[0.875rem] font-medium text-[#202020] text-start p-0"
                      )}
                    >
                      <div
                        className={clsx(
                          "w-full px-3 py-4 bg-[#F2F7F3] flex flex-row items-center gap-1 transition-all",
                          index === headerGroup.headers.length - 1 &&
                            "rounded-tr-[14px] rounded-br-[14px]",
                          index === 0 && "rounded-tl-[14px] rounded-bl-[14px]"
                        )}
                        // onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {/* {{
                          asc: <ArrowIcon />,
                          desc: <ArrowIcon className="rotate-[180deg]" />,
                        }[header.column.getIsSorted() as string] ?? null} */}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {!isLoading &&
                table.getRowModel().rows.map((row, index) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={clsx(
                          "px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]",
                          // last index no border
                          index === table.getRowModel().rows.length - 1 &&
                            "border-b-0"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              {isFetching && (
                <TableSkeleton
                  rows={5}
                  columns={table.getAllColumns().length}
                />
              )}
            </tbody>
          </table>
          {data?.pages?.[0]?.length === 0 && !isFetching && (
            <div className="h-[calc(100%-120px)] flex flex-col justify-center items-center w-full text-sm">
              No deactivated users found
            </div>
          )}
        </div>

        <div className="">
          <button
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          >
            {/* {isFetchingNextPage
              ? "Loading more..."
              : hasNextPage
              ? "Load More"
              : "Nothing more to load"} */}
          </button>
        </div>
        {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
      </div>
    </FullScreenModal>
  );
}
