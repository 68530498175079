import { ReactComponent as FilterIcon } from "../../assets/icons/filters.svg";
import { ReactComponent as DotsIcon } from "../../assets/icons/more.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/icons/chevron-left.svg";
import UserIcon from "../../assets/icons/profile-icon.svg";
import {
  SortingState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useEffect, useMemo, useRef, useState } from "react";
import SubscriptionBadge from "../../components/SubscriptionBadge";
import UIPopover from "../../components/Popover";
// import UserDetails, { ConfirmBlock, ConfirmDelete } from "./UserDetails";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import moment from "moment";
import { ShowToast } from "../../components/toast";
// import BlockedUsers from "./BlockedUsers";
import clsx from "clsx";
import Filters from "./Filter";
import { SelectedFilters } from "./SelectedFilters";
import { Button } from "../../components/Button";
import SearchComponent from "../../components/Search";
import BlockedUsers from "./BlockedUsers";
import { ConfirmBlock, ConfirmDelete } from "./UserDetails";
import { DBUserType, Tabs, UserTabs } from "./UserTabs";
import { UserDetails } from "./UserDetails/UserDetails";
import InactiveUsers from "./InactiveUsers";

type Person = {
  user_id: string;
  first_name: string;
  last_name: string;
  email_id: string;
  date_created: string;
  tier: string;
  last_active_date: string;
  user_image: string;
  isActive: boolean;
  mobile?: string;
  lessons_viewed: number;
  user_name: string;
};

const columnHelper = createColumnHelper<Person>();

export default function Users() {
  const [sortBy, setSortBy] = useState<"first_name" | undefined>();
  const [sortOrder, setSortOrder] = useState<"ASC" | "DESC" | undefined>();
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [isUserDetailsOpen, setIsUserDetailsOpen] = React.useState<any>(false);
  const [selectedUser, setSelectedUser] = React.useState<Person | null>(null);
  const [search, setSearch] = React.useState("");
  const [tableData, setTableData] = React.useState<Person[]>([]);
  const [isBlockedUsers, setIsBlockedUsers] = React.useState(false);
  const [isInactiveUsers, setIsInactiveUsers] = React.useState(false);
  const loadMoreRef = useRef<any>(null);
  const [filters, setFilters] = useState<{
    date: { startDate?: string; endDate?: string };
    types?: string[];
    selectedDateFilter?: string;
  }>();
  const [tempFilters, setTempFilters] = React.useState<{
    date: { startDate: string; endDate: string };
    types: string[];
    selectedDateFilter: string;
  }>({
    date: { startDate: "", endDate: "" },
    types: [],
    selectedDateFilter: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
  const queryClient = useQueryClient();

  const handleClose = () => {
    setIsUserDetailsOpen(false);
    setSelectedUser(null);
  };

  const fetchUsers = async ({ pageParam }) => {
    const res = await api.getUsers({
      page: pageParam,
      limit: 10,
      keyword: search,
      status: "active",
      sortBy,
      sortOrder,
    });
    return res;
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ["users", search, filters, sortBy, sortOrder],
    queryFn: fetchUsers,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, pageParam) => {
      console.log(lastPage, "lastPage");
      if (lastPage?.data?.user?.length < 10) return null;
      return pageParam + 1;
    },
  });

  console.log(data, "DDDDD");

  const columns = [
    columnHelper.accessor("first_name", {
      header: ({ column }) => {
        return (
          <button
            onClick={() => {
              if (sortBy === "first_name") {
                setSortOrder(sortOrder === "ASC" ? "DESC" : "ASC");
              } else {
                setSortBy("first_name");
                setSortOrder("ASC");
              }
            }}
            className="p-0 flex items-center gap-[2px]"
          >
            Full name
            {sortBy === "first_name" ? (
              sortOrder === "ASC" ? (
                <ArrowDownIcon className="w-4 h-4" />
              ) : (
                <ArrowDownIcon className="w-4 h-4 rotate-[180deg]" />
              )
            ) : null}
          </button>
        );
      },
      cell: (info) => (
        <div className="flex flex-row items-center gap-2.5">
          <img
            src={info.row.original.user_image || UserIcon}
            alt="user"
            className="w-10 h-10 rounded-full object-fill"
          />
          <div className="flex flex-col gap-0.5">
            <span>
              {info.row.original.first_name} {info.row.original.last_name}
            </span>
            <div className="text-[13px] text-black/40 poppins font-medium">
              @{info.row.original?.user_name}
            </div>
          </div>
        </div>
      ),
    }),
    columnHelper.accessor((row) => row.email_id, {
      header: "Email",
    }),
    columnHelper.accessor((row) => row.mobile, {
      header: "Phone",
    }),
    columnHelper.accessor("lessons_viewed", {
      header: "# of lessons viewed",
    }),
    columnHelper.accessor("date_created", {
      header: "Joined on",
      cell: (info) => moment(info.getValue()).format("MMM DD, YYYY") || "-",
    }),
    columnHelper.accessor("last_active_date", {
      header: "Last activity",
      cell: (info) => (
        <div className="flex flex-row justify-between w-full items-center">
          {info.getValue()
            ? moment(info.getValue()).local().format("MMM DD, YYYY | hh:mmA")
            : "-"}
          <UIPopover
            trigger={
              <Button
                variant="outline"
                className="hover:!border-[#9D9D9D] hover:!bg-transparent !border-[#D7D7D7] !rounded-md h-[2.5rem] w-[2.5rem] flex !p-2 items-center justify-center [&_circle]:fill-black"
              >
                <DotsIcon />
              </Button>
            }
          >
            {(close) => (
              <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
                <button
                  className="text-start"
                  onClick={() => {
                    setSelectedUser(info.row.original);
                    setIsUserDetailsOpen(info.row.original);
                  }}
                >
                  View profile
                </button>
                <button
                  className="text-start"
                  onClick={() => {
                    setIsBlockModalOpen(true);
                    setSelectedUser(info.row.original);
                  }}
                >
                  Deactivate user
                </button>
                <button
                  className="text-[#F54343] text-start"
                  onClick={() => {
                    setIsDeleteModalOpen(true);
                    setSelectedUser(info.row.original);
                  }}
                >
                  Delete user
                </button>
              </div>
            )}
          </UIPopover>
        </div>
      ),
    }),
  ];

  const table = useReactTable<Person>({
    data: tableData || [], // Correctly set the data property
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(), //client-side sorting
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  useMemo(() => {
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap((page) => page?.data?.user);
      // Set the combined data into the table data state
      console.log(newData, "newData");
      setTableData(newData);
    }
  }, [data]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const hasFilters = useMemo(() => {
    return filters?.date.startDate || (filters?.types?.length || 0) > 0;
  }, [filters]);

  return (
    <div className="flex flex-col gap-4">
      {!!isUserDetailsOpen && (
        <UserDetails
          isOpen={!!isUserDetailsOpen}
          onClose={handleClose}
          user={isUserDetailsOpen}
          refresh={refetch}
        />
      )}
      {isBlockedUsers && (
        <BlockedUsers
          isOpen={isBlockedUsers}
          handleClose={() => setIsBlockedUsers(false)}
          refetchActiveUsers={refetch}
        />
      )}
      {isInactiveUsers && (
        <InactiveUsers
          isOpen={isInactiveUsers}
          handleClose={() => setIsInactiveUsers(false)}
          refetchActiveUsers={refetch}
        />
      )}
      {isDeleteModalOpen && selectedUser && (
        <ConfirmDelete
          onClose={() => setIsDeleteModalOpen(false)}
          userId={selectedUser.user_id}
          refetch={() => {
            refetch();
          }}
          name={`${selectedUser.first_name} ${selectedUser.last_name}`}
        />
      )}
      {isBlockModalOpen && selectedUser && (
        <ConfirmBlock
          onClose={() => setIsBlockModalOpen(false)}
          userId={selectedUser.user_id}
          refetch={() => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            queryClient.invalidateQueries({ queryKey: ["blocked-users"] });
          }}
          name={`${selectedUser.first_name} ${selectedUser.last_name}`}
        />
      )}

      <div className="flex flex-row justify-between">
        <h1 className="text-black text-2xl font-bold leading-[28.8px]">
          Manage users
        </h1>
        <div className="flex flex-row gap-2 items-center">
          <Button
            variant="link"
            onClick={() => setIsInactiveUsers(!isInactiveUsers)}
            className="!text-[#393939] !cursor-pointer"
          >
            Inactive Users{" "}
            <ArrowLeftIcon className="w-4 h-4 [&_path]:stroke-primary" />
          </Button>
          <Button
            variant="link"
            onClick={() => setIsBlockedUsers(!isBlockedUsers)}
            className="!text-[#393939] !cursor-pointer"
          >
            Blocked Users{" "}
            <ArrowLeftIcon className="w-4 h-4 [&_path]:stroke-primary" />
          </Button>
        </div>
      </div>
      <div className="bg-white p-5 flex flex-col">
        <div className="flex flex-row justify-between items-center mb-5">
          <div className="flex items-center gap-3">
            <SearchComponent
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              placeholder="Search"
              value={search}
            />
          </div>
          {/* <div className="flex flex-row gap-2 items-center">
            <Filters
              setFilters={setFilters}
              tempFilters={tempFilters}
              setTempFilters={setTempFilters}
            />
          </div> */}
        </div>
        {/* <SelectedFilters
          filters={filters}
          setFilters={setFilters}
          hasFilters={
            filters?.date.startDate || (filters?.types?.length || 0) > 0
          }
          setTempFilters={setTempFilters}
        /> */}

        <div
          className={`overflow-auto ${
            hasFilters ? "h-[calc(100vh-260px)]" : "h-[calc(100vh-190px)]"
          } relative flex flex-col`}
        >
          <table className="w-full relative">
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "20%" }} />
              <col style={{ width: "10%" }} />
              <col style={{ width: "16%" }} />
              <col style={{ width: "12%" }} />
              <col style={{ width: "29%" }} />
            </colgroup>
            <thead className="w-full">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id} className="sticky top-0">
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={clsx(
                        "text-[0.875rem] font-medium text-[#202020] text-start p-0"
                      )}
                    >
                      <div
                        className={clsx(
                          "w-full px-3 py-4 bg-[#F2F7F3] flex flex-row items-center gap-1 transition-all",
                          index === headerGroup.headers.length - 1 &&
                            "rounded-tr-[14px] rounded-br-[14px]",
                          index === 0 && "rounded-tl-[14px] rounded-bl-[14px]"
                        )}
                        // onClick={header.column.getToggleSortingHandler()}
                      >
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                        {/* {{
                          asc: <ArrowIcon />,
                          desc: <ArrowIcon className="rotate-[180deg]" />,
                        }[header.column.getIsSorted() as string] ?? null} */}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {!isLoading &&
                table.getRowModel().rows.map((row, index) => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className={clsx(
                          "px-3 py-4 border-b text-black/80 border-b-[#D7D7D7] text-[13px] text-left",
                          // last index no border
                          index === table.getRowModel().rows.length - 1 &&
                            "border-b-0"
                        )}
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}

              {isFetching && (
                <TableSkeleton
                  rows={5}
                  columns={table.getAllColumns().length}
                />
              )}
            </tbody>
          </table>
          {!isLoading && !isFetching && tableData.length === 0 && (
            <div className="flex-1 flex justify-center items-center">
              <span className="text-[#999999] text-[0.875rem] font-medium">
                No data available
              </span>
            </div>
          )}
          <div className="">
            <button
              onClick={() => fetchNextPage()}
              disabled={!hasNextPage || isFetchingNextPage}
              ref={loadMoreRef}
            >
              {/* {isFetchingNextPage
                ? "Loading more..."
                : hasNextPage
                ? "Load More"
                : "Nothing more to load"} */}
            </button>
          </div>
          {/* <div>{isFetching && !isFetchingNextPage ? "Fetching..." : null}</div> */}
        </div>
      </div>
    </div>
  );
}

export function TableSkeleton({
  rows,
  columns,
}: {
  rows: number;
  columns: number;
}) {
  return (
    <>
      {Array.from({ length: rows }).map((_, rowIndex) => (
        <tr key={`skeleton-row-${rowIndex}`} className="animate-pulse">
          {Array.from({ length: columns }).map((_, colIndex) => (
            <td
              key={`skeleton-cell-${rowIndex}-${colIndex}`}
              className="px-5 py-4 border-b border-b-[#D7D7D7] font-medium text-[0.8125rem]"
            >
              <div className="w-full h-4 bg-gray-300 rounded-md"></div>
            </td>
          ))}
        </tr>
      ))}
    </>
  );
}
