export function TextInput({
  icon,
  ...props
}: React.InputHTMLAttributes<HTMLInputElement> & { icon?: React.ReactNode }) {
  return (
    <div className="w-full relative">
      {icon && (
        <div className="absolute left-4 top-1/2 -translate-y-1/2">{icon}</div>
      )}
      <input
        {...props}
        className={`h-12 ${
          icon ? "!pl-10" : ""
        } !outline-none w-full focus:bg-white rounded-lg border border-[#D9D9E7] focus:border-grey2 p-3 text-sm text-black ${
          props.className || ""
        }`}
      />
    </div>
  );
}
