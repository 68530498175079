import { ReactComponent as UsersIcon } from "../../assets/icons/users.svg";
import { ReactComponent as StylistIcon } from "../../assets/icons/stylist.svg";
import { ReactComponent as SalonIcon } from "../../assets/icons/salons.svg";
import { ReactComponent as CourseIcon } from "../../assets/icons/course.svg";
import { ReactComponent as ActiveUserIcon } from "../../assets/icons/active-user.svg";
import { ReactComponent as InactiveUserIcon } from "../../assets/icons/inactive-user.svg";

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { api } from "../../helpers/api";
import Chart from "./Chart";
import { Link } from "react-router-dom";
import { useUser } from "../auth/context/user-context";
import RecentSubscriptions from "./RecentSubscriptions";
import { formatNumber } from "../../helpers/utils";
import { useState } from "react";
import { ConfirmBlock, ConfirmDelete } from "../users/UserDetails";
import { UserDetails } from "../users/UserDetails/UserDetails";

function Card({
  title,
  value,
  icon,
}: {
  title: string;
  value: number;
  icon: React.ReactNode;
}) {
  return (
    <div className="flex gap-4 p-4 flex-1 items-center rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
      <div className="bg-secondary w-16 rounded-full h-16 flex items-center justify-center">
        {icon}
      </div>
      <div className="flex flex-col gap-1">
        <span className=" font-medium leading-[113.6%] text-base">{title}</span>
        <span className="font-bold text-2xl text-[#101828]">
          {formatNumber(value || 0)}
        </span>
      </div>
    </div>
  );
}

export default function Dashboard() {
  const queryClient = useQueryClient();
  const { userInfo: user } = useUser() as any;
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["dashboard"],
    queryFn: () => api.dashboardCounts(),
    // enabled: !!user,
  });
  const [isUserDetailsOpen, setIsUserDetailsOpen] = useState<any>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [isBlockModalOpen, setIsBlockModalOpen] = useState<any>(false);

  const handleClose = () => {
    setIsUserDetailsOpen(false);
  };

  console.log(data);
  return (
    <div className="flex flex-col gap-4">
      <h1 className="text-black text-2xl font-bold leading-[28.8px]">
        Dashboard
      </h1>
      {!isLoading && (
        <div className="flex flex-row gap-[1.125rem] flex-wrap">
          <Card
            title="Total Users"
            value={data?.data?.totalUsers}
            icon={<UsersIcon />}
          />
          <Card
            title="Active Users"
            value={data?.data?.activeUsers}
            icon={<ActiveUserIcon />}
          />
          <Card
            title="Inactive Users"
            value={data?.data?.inActiveUsers}
            icon={<InactiveUserIcon />}
          />
          <Card
            title="Total # of courses"
            value={data?.data?.totalCourses}
            icon={<CourseIcon />}
          />
        </div>
      )}
      {isLoading && <LoadingSkeleton />}

      <div className="flex flex-row gap-[1.125rem] flex-wrap">
        <Chart />
        <RecentSubscriptions
          onView={(data) => setIsUserDetailsOpen(data)}
          onDelete={(data) => setIsDeleteModalOpen(data)}
          onBlock={(data) => setIsBlockModalOpen(data)}
        />
      </div>
      {!!isUserDetailsOpen && (
        <UserDetails
          isOpen={!!isUserDetailsOpen}
          onClose={handleClose}
          user={isUserDetailsOpen.user}
          refresh={refetch}
        />
      )}
      {isDeleteModalOpen && (
        <ConfirmDelete
          onClose={() => setIsDeleteModalOpen(false)}
          userId={isDeleteModalOpen.id}
          refetch={() => {
            // refetch();
          }}
          name={isDeleteModalOpen.fullName}
        />
      )}
      {isBlockModalOpen && (
        <ConfirmBlock
          onClose={() => setIsBlockModalOpen(false)}
          userId={isBlockModalOpen.id}
          refetch={() => {
            queryClient.invalidateQueries({ queryKey: ["most-active-users"] });
            queryClient.invalidateQueries({ queryKey: ["blocked-users"] });
            refetch();
          }}
          name={isBlockModalOpen.fullName}
        />
      )}
    </div>
  );
}

function LoadingSkeleton() {
  return (
    <div className="flex flex-row gap-[1.125rem] flex-wrap animate-pulse">
      <div className="flex gap-4 p-6 flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
        <div className="bg-secondary w-16 rounded-full h-16 flex items-center justify-center">
          <UsersIcon />
        </div>
        <div className="flex flex-col gap-1">
          <span className=" font-medium leading-[113.6%] text-base">
            Total Users
          </span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
      <div className="flex gap-4 p-6 flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
        <div className="bg-secondary w-16 rounded-full h-16 flex items-center justify-center">
          <StylistIcon />
        </div>
        <div className="flex flex-col gap-1">
          <span className=" font-medium leading-[113.6%] text-base">
            Total Stylists
          </span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
      <div className="flex gap-4 p-6 flex-1 rounded-[16px] bg-white shadow-[0px_8px_20px_0px_rgba(0,0,0,0.03)]">
        <div className="bg-secondary w-16 rounded-full h-16 flex items-center justify-center">
          <SalonIcon />
        </div>
        <div className="flex-1 flex flex-col gap-1">
          <span className=" font-medium leading-[113.6%] text-base">
            Total Salons
          </span>
          <div className="font-bold text-2xl text-[#101828] w-24 h-4 bg-gray-300 rounded-md"></div>
        </div>
      </div>
    </div>
  );
}
