import { useState, useEffect } from "react";
import { PopupModal } from "../../../../components/PopupModal";
import { VideoForm } from "./VideoForm";
import { Button } from "../../../../components/Button";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { api } from "../../../../helpers/api";

export function SingleVideoPopup({
  open,
  onClose,
  onUpdate,
  data,
  videoDetails,
}: any) {
  const [saving, setSaving] = useState(false);
  const [videoData, setVideoData] = useState({});

  const onChange = (data: any) => {
    if (data) {
      setVideoData(data);
    }
  };

  const onSubmit = async () => {
    console.log(videoData, data);
    try {
      setSaving(true);
      let res;
      if (videoDetails?.sub_video_id) {
        res = await api.updateSubVideo(videoDetails?.sub_video_id, {
          ...videoData,
        });
      } else {
        res = await api.saveSubVideo({
          ...videoData,
          video_id: data?.video_id,
        });
      }
      setSaving(false);
      if (res?.status === 200) {
        onUpdate();
        onClose();
      }
    } catch (error) {
      setSaving(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (videoDetails) {
      setVideoData(videoDetails);
    }
  }, [videoDetails]);

  console.log(data, videoDetails);
  return (
    <PopupModal open={open} onClose={onClose}>
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-semibold">Add Lesson</h2>
        <button onClick={onClose}>
          <CloseIcon />
        </button>
      </div>
      <VideoForm onChange={onChange} data={videoData} />
      <div className="flex justify-end mt-5">
        <Button variant="primary" onClick={onSubmit} loading={saving}>
          {videoDetails?.sub_video_id ? "Update" : "Add"}
        </Button>
      </div>
    </PopupModal>
  );
}
