import axios from "axios";
import { api } from "./api";

export const fileUpload = async (file: File, folder: string) => {
  const uploadPayload = {
    fileName: file.name,
    folder,
    contentType: file.type,
  };
  const { data } = await api.getFileUpload(uploadPayload);

  console.log(data);

  const response = await axios.put(data.uploadURL, file, {
    headers: { "Content-Type": file.type },
  });
  return { response, url: data.uploadURL.split("?")[0] };
};
