import { useEffect, useState } from "react";
import { api } from "../../../../helpers/api";
import { ShowToast } from "../../../../components/toast";
import { PopupModal } from "../../../../components/PopupModal";
import { Button } from "../../../../components/Button";

export function ConfirmDeletePost({
  onClose,
  postId,
  refetch,
  onDelete,
  isLoading,
}: {
  onClose: () => void;
  postId: string;
  refetch: () => void;
  onDelete: () => void;
  isLoading: boolean;
}) {
  useEffect(() => {
    //disable any scrolling when the modal is open
    // disable scroll of .full-screen-modal-content
    document.body.style.overflow = "hidden";
    const element = document.querySelector(".full-screen-modal-content");
    if (element) {
      // @ts-expect-error
      element.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
      if (element) {
        // @ts-expect-error
        element.style.overflow = "auto";
      }
    };
  }, []);

  return (
    <PopupModal
      open={true}
      // containerClassName="!z-[100]"
      onClose={onClose}
      contentClassName="w-[500px] mx-auto"
    >
      <p>
        Are you sure you want to delete this post? This action cannot be undone.
      </p>
      <div className="flex flex-row gap-4 ml-auto">
        <Button variant="outline" className="px-8" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="px-8 text-[1rem]"
          onClick={onDelete}
          loading={isLoading}
          variant="primary"
        >
          Delete
        </Button>
      </div>
    </PopupModal>
  );
}
