import LogoIcon from "../assets/icons/logo.svg";
import { Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import { ReactComponent as LogoutIcon } from "../assets/icons/logout.svg";
import { ReactComponent as SideBarHue } from "../assets/icons/sidebar-hue.svg";
import { ReactComponent as DashboardIcon } from "../assets/icons/dashboard.svg";
import { ReactComponent as LearnIcon } from "../assets/icons/learn.svg";
import { ReactComponent as ContentIcon } from "../assets/icons/content.svg";
import { ReactComponent as UsersIcon } from "../assets/icons/user.svg";

import { clearTokenAndUser } from "../helpers/utils";
import { Button } from "./Button";

export default function Sidebar() {
  const navigate = useNavigate();

  const handleLogout = () => {
    clearTokenAndUser();
    localStorage.clear();
    navigate("/login");
  };

  // Desktop Sidebar
  return (
    <div className="lg:flex flex-col h-[100dvh] relative hidden min-w-[242px] w-[242px] p-3 border-r border-[#C2D3C9]">
      <div className="mb-4 px-4 py-5 z-[1]">
        <Link
          to="/home"
          className="flex font-outfit font-[500] text-[1.125rem] justify-center items-center gap-1"
        >
          <img src={LogoIcon} alt="logo" className="h-[24px] w-[129px]" />
        </Link>
      </div>

      <div className="grid gap-[0.375rem] z-[1]">
        <CustomLink
          to="/dashboard"
          activePath="/dashboard"
          label="Dashboard"
          icon={<DashboardIcon />}
        />
        <CustomLink
          to="/users"
          activePath="/users"
          label="Manage users"
          icon={<UsersIcon />}
        />
        <CustomLink
          to="/contents"
          activePath="/contents"
          label="Manage content"
          icon={<ContentIcon />}
        />
        <CustomLink
          to="/topics"
          activePath="/topics"
          label="Manage topics"
          icon={<LearnIcon />}
        />

        <button
          onClick={handleLogout}
          className="absolute bottom-3 hover:opacity-80 cursor-pointer w-[calc(100%-24px)] p-3 rounded-lg flex flex-row items-center gap-2"
        >
          <LogoutIcon width={24} height={24} />
          <span className="text-[#5B5B5B] font-medium text-base">Logout</span>
        </button>
      </div>
    </div>
  );
}

function CustomLink({
  to,
  activePath,
  label,
  icon,
}: {
  to: string;
  activePath: string;
  label: string;
  icon: React.ReactNode;
}) {
  const isActive = window.location.pathname === activePath;

  return (
    <Link to={to}>
      <Button
        className={clsx(
          `!px-3 !rounded-md py-2.5 border-0 hover:bg-[#355E3B]/30 flex flex-row !justify-start gap-2 w-full items-center ${
            to !== "/topics" ? "[&_path]:fill-black" : "[&_path]:stroke-black"
          }`,
          {
            "!bg-[#355E3B] shadow-[0px_1px_4px_0px_rgba(100,182,103,0.11)] [&_path]:!stroke-white":
              isActive && to === "/topics",
          },
          {
            "!bg-[#355E3B] shadow-[0px_1px_4px_0px_rgba(100,182,103,0.11)] [&_path]:!fill-white":
              isActive && to !== "/topics",
          }
        )}
        variant={isActive ? "primary" : "outline"}
      >
        {icon}
        <span
          className={clsx(
            { "!text-black": !isActive },
            "text-white font-[500] text-[1rem]"
          )}
        >
          {label}
        </span>
      </Button>
    </Link>
  );
}
