import clsx from "clsx";
import { ReactComponent as ClockIcon } from "../../assets/icons/clock.svg";
import moment from "moment";
import UserIcon from "../../assets/icons/profile-icon.svg";
import UIPopover from "../../components/Popover";
import { Button } from "../../components/Button";
import { ReactComponent as MoreIcon } from "../../assets/icons/more.svg";

export default function UserCard({
  className,
  user,
  onView,
  onBlock,
  onDelete,
}: {
  className?: string;
  user?: any;
  onView: () => void;
  onBlock: () => void;
  onDelete: () => void;
}) {
  return (
    <div
      className={clsx(
        "flex  flex-row items-center py-4 px-5 gap-4 rounded-md border border-b-[#DDD] justify-between",
        className
      )}
    >
      <div className="flex flex-row items-center gap-4">
        <div className="w-[50px] h-[50px] rounded-full">
          <img
            src={user?.profileImg || UserIcon}
            className="w-full h-full rounded-full"
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="text-base poppins text-black font-medium">
            {user?.fullName}
          </span>
          <div className="text-[13px] text-black/40 poppins font-medium">
            @{user?.username}
          </div>
        </div>
      </div>
      <div className="flex gap-2 items-center">
        <div className="text-xs poppins font-medium text-black/60">
          Last active {moment(user?.lastActive || moment().format()).fromNow()}
        </div>
        <UIPopover
          trigger={
            <Button
              variant="outline"
              className="!rounded-md h-[2.5rem] hover:!bg-transparent !border-0 w-[2.5rem] flex !p-2 items-center justify-center [&_circle]:fill-black [&_circle]:hover:fill-black/50"
            >
              <MoreIcon />
            </Button>
          }
        >
          {(close) => (
            <div className="flex flex-col gap-4 bg-white p-4 rounded-lg shadow-[0px_6px_26px_0px_rgba(28,43,40,0.14)] font-inter mr-4 text-sm">
              <button className="text-start" onClick={onView}>
                View profile
              </button>
              <button className="text-start" onClick={onBlock}>
                Deactivate user
              </button>
              <button className="text-[#F54343] text-start" onClick={onDelete}>
                Delete user
              </button>
            </div>
          )}
        </UIPopover>
      </div>
    </div>
  );
}

export function UserCardSkeleton({
  className,
  noBorder,
}: {
  className?: string;
  noBorder?: boolean;
}) {
  return (
    <div
      className={clsx(
        "flex flex-row items-center py-4 px-5 gap-4 border-b border-b-[#D7D7D7] justify-between animate-pulse",
        className,
        {
          "border-b-0": noBorder,
        }
      )}
    >
      <div className="flex flex-row gap-4">
        <div className="w-[50px] h-[50px] rounded-full">
          <img
            src="https://via.placeholder.com/50x50"
            className="w-full h-full"
          />
        </div>
        <div className="flex flex-col gap-2.5">
          <span className="text-lg leading-normal font-medium w-36 h-4 bg-gray-300 rounded-md"></span>
          <div className="flex flex-row gap-1 items-center">
            <ClockIcon width={18} height={18} />
            <span className="text-sm text-[#666] w-24 h-3 bg-gray-300 rounded-md"></span>
          </div>
        </div>
      </div>
      <div className="w-24 h-5 bg-gray-300 rounded-md"></div>
    </div>
  );
}
