import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { ReactComponent as TrashIcon } from "../../assets/icons/trash.svg";
import { TextInput } from "../../components/TextInput";
import { SelectInput } from "../../components/SelectInput";
import { Button } from "../../components/Button";
import { useEffect, useRef, useState } from "react";
import { api } from "../../helpers/api";
import { fileUpload } from "../../helpers/fileUpload";
import { PopupModal } from "../../components/PopupModal";

export function TopicForm({
  onClose,
  topic,
  onUpdate,
}: {
  onClose: () => void;
  topic?: any;
  onUpdate?: (data: any) => void;
}) {
  const [formData, setFormData] = useState<any>({
    name: "",
    sub_topics: [{ id: 1 }],
  });
  const [saving, setSaving] = useState(false);

  const handleSubmit = async () => {
    try {
      setSaving(true);
      console.log(formData);
      const data: any = {
        ...formData,
        sub_topics: formData?.sub_topics?.map((topic: any) => topic?.text),
      };
      const API = topic?.topic_id
        ? api.updateTopic(topic?.topic_id, data)
        : api.createTopic(data);
      API.then((res) => {
        console.log(res);
        setSaving(false);
        onUpdate?.(res?.data);
        handleClose();
      }).catch(() => {
        setSaving(false);
      });
    } catch (error) {
      setSaving(false);
    }
  };

  const addSubTopic = () => {
    setFormData({
      ...formData,
      sub_topics: [
        ...formData?.sub_topics,
        {
          id:
            Math.max(
              ...formData?.sub_topics?.map((subTopic: any) => subTopic?.id)
            ) + 1,
        },
      ],
    });
  };

  const removeSubTopic = (index: number) => {
    const newSubTopics = [...formData?.sub_topics];
    newSubTopics.splice(index, 1);
    setFormData({ ...formData, sub_topics: newSubTopics });
  };

  useEffect(() => {
    if (topic) {
      setFormData({
        ...topic,
        sub_topics: topic?.sub_topics?.map((subTopic: any, index: number) => ({
          id: index + 1,
          text: subTopic,
        })),
      });
    }
  }, [topic]);

  const handleClose = () => {
    setFormData({
      name: "",
      sub_topics: [{ id: 1 }],
    });
    onClose();
  };

  const disabled = !formData?.name;

  return (
    <PopupModal
      contentClassName="w-[436px] mx-auto"
      open={true}
      onClose={handleClose}
      showCloseButton={false}
    >
      <div className="flex justify-between items-center">
        <h1 className="text-lg text-black font-bold tracking-[-0.2px]">
          {topic?.topic_id ? "Edit" : "Add"} topic
        </h1>
        <button onClick={handleClose}>
          <CloseIcon />
        </button>
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-1.5">
          <label className="text-xs text-black font-medium tracking-[-0.24px]">
            Topic name
          </label>
          <TextInput
            onChange={(e) => {
              setFormData({ ...formData, name: e.target.value });
            }}
            value={formData?.name}
            name="name"
            placeholder="Enter here"
          />
        </div>
        <div>
          <label className="text-xs text-black font-medium tracking-[-0.24px]">
            Subcategories
          </label>
          <div className="flex flex-col gap-1.5 mb-1.5">
            {formData?.sub_topics?.map((subTopic: any, index: number) => (
              <div className="relative" key={subTopic.id}>
                <TextInput
                  onChange={(e) => {
                    const newSubTopics = [...formData?.sub_topics];
                    newSubTopics[index] = {
                      ...subTopic,
                      text: e.target.value,
                    };
                    setFormData({ ...formData, sub_topics: newSubTopics });
                  }}
                  value={subTopic.text}
                  className="pr-8"
                />
                <button
                  onClick={() => removeSubTopic(index)}
                  className="absolute right-4 top-4"
                >
                  <TrashIcon />
                </button>
              </div>
            ))}
          </div>
          <button
            onClick={addSubTopic}
            className="text-xs h-[36px] w-full flex items-center justify-center text-black/80 font-medium tracking-[-0.24px] border-[rgba(53, 94, 59, 0.40)] border rounded-[6px] bg-[#E4EBE4]"
          >
            + Add another subcategory
          </button>
        </div>
        <div>
          <Button
            variant="primary"
            onClick={handleSubmit}
            loading={saving}
            disabled={disabled}
            className="w-full"
          >
            {topic?.topic_id ? "Update" : "Add"}
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}
