import { useEffect, useMemo, useRef, useState } from "react";
import usePosts from "../usePosts";
import { TABS } from "./Tabs";
import { PostCard } from "./PostCard";
import Spinner from "../../../../components/Spinners";
import { api } from "../../../../helpers/api";
import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import { ConfirmDeletePost } from "./ConfirmDeletePost";
import { ShowToast } from "../../../../components/toast";

export function LikedPosts({ user, refresh, type }) {
  const queryClient = useQueryClient();
  const loadMoreRef = useRef<any>(null);
  const [deletePostConfirmation, setDeletePostConfirmation] =
    useState<any>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<any>(false);
  const [isDeletingPost, setIsDeletingPost] = useState(false);

  const fetchUsers = async ({ pageParam }) => {
    const API =
      type === TABS.LIKED ? api.getUserLikedCourses : api.getUserSavedCourses;
    const res = await API({
      id: user?.user_id,
      page: pageParam,
      limit: 10,
    });
    console.log("res", res);
    return res?.data || [];
  };
  const queryName =
    type === TABS.LIKED ? "infinite-liked-posts" : "infinite-bookmarked-posts";
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: [queryName, user?.user_id, type],
    queryFn: fetchUsers,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages, pageParam) => {
      console.log(lastPage);
      const field = type === TABS.LIKED ? "videoLikes" : "videoSaved";
      if (lastPage?.[field]?.length < 10) return null;
      return pageParam + 1;
    },
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasNextPage && !isFetchingNextPage) {
          fetchNextPage();
        }
      },
      {
        root: null,
        rootMargin: "20px",
        threshold: 1.0,
      }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [hasNextPage, isFetchingNextPage, fetchNextPage]);

  const posts = useMemo(() => {
    console.log("data", data, type);
    if (data) {
      // Combine pages into one array
      const newData = data.pages.flatMap(
        (page) => page?.[type === TABS.LIKED ? "videoLikes" : "videoSaved"]
      );
      // Set the combined data into the table data state
      return newData;
    }
  }, [data, type]);

  const handleDelete = () => {
    console.log("deletePostConfirmation", deletePostConfirmation, type);
    // setIsDeletingPost(true);
    // api
    //   .deletePost(deletePostConfirmation?.id)
    //   .then(() => {
    //     ShowToast({
    //       message: "Post deleted successfully",
    //       type: "success",
    //     });
    //     refresh();
    //     setDeletePostConfirmation(false);
    //     queryClient.invalidateQueries({ queryKey: ["posts"] });

    //     setIsDeletingPost(false);
    //   })
    //   .catch(() => {
    //     ShowToast({
    //       message: "Failed to delete post",
    //       type: "error",
    //     });
    //     setIsDeletingPost(false);
    //   });
  };

  console.log(data);
  return (
    <div className="mt-4 flex p-5  bg-white rounded-[12px]">
      <div className="h-[calc(100vh-350px)] overflow-auto w-full flex flex-col gap-3">
        {!isLoading && posts?.length === 0 && <div>No posts found</div>}
        <div className="flex flex-col gap-4">
          {posts?.map((post, index) => {
            return (
              <PostCard
                user={user}
                key={post.id}
                type={type}
                post={post}
                onPostDelete={() => {
                  setDeletePostConfirmation(post);
                }}
                refresh={() => {
                  refetch();
                  refresh();
                }}
              />
            );
          })}
        </div>

        {isLoading && (
          <div className="flex justify-center [&_svg]:text-slate-900">
            <Spinner />
          </div>
        )}

        <div className="">
          <button
            type="button"
            onClick={() => fetchNextPage()}
            disabled={!hasNextPage || isFetchingNextPage}
            ref={loadMoreRef}
          >
            {/* {isFetchingNextPage
                  ? "Loading more..."
                  : hasNextPage
                  ? "Load More"
                  : "Nothing more to load"} */}
          </button>
        </div>
      </div>

      {deletePostConfirmation.id && (
        <ConfirmDeletePost
          onClose={() => setDeletePostConfirmation(false)}
          refetch={refresh}
          postId={deletePostConfirmation.id}
          onDelete={() => handleDelete()}
          isLoading={isDeletingPost}
        />
      )}
    </div>
  );
}
