import { Rating } from "./Rating";
import { ReactComponent as MenuDotsIcon } from "../../../../assets/icons/menu-dots.svg";
import { ReactComponent as HeartIcon } from "../../../../assets/icons/heart.svg";
import { ReactComponent as BookmarkIcon } from "../../../../assets/icons/bookmark.svg";
import { ReactComponent as CommentsIcon } from "../../../../assets/icons/comments.svg";

import { TABS } from "./Tabs";
import { ImageListView } from "../../../../components/ImageListView";
import moment from "moment";
import UIPopover from "../../../../components/Popover";
import { Button } from "../../../../components/Button";
import { getMentions } from "../../../../helpers/utils";

export function PostCard({
  type,
  post,
  user,
  refresh,
  onPostDelete,
  postDeleteLoading,
  isSalon,
}: {
  type: string;
  post: any;
  user: any;
  refresh: any;
  onPostDelete?: any;
  postDeleteLoading?: boolean;
  isSalon?: boolean;
}) {
  console.log(post);
  const videoData = post?.video_data;
  return (
    <div className="h-24 pl-3 w-full py-3 bg-white rounded-xl border border-[#e4ebe4] justify-start items-center gap-4 flex">
      <img
        className="w-[72px] h-[72px] rounded-lg"
        src="https://via.placeholder.com/72x72"
        alt="post"
      />
      <div className="grow shrink basis-0 flex-col justify-center items-start gap-1.5 inline-flex">
        <div className="self-stretch text-black text-[15px] font-semibold font-['Montserrat'] leading-snug">
          {videoData?.description}
        </div>
        <div>
          <span className="text-[#444444] text-sm font-medium font-['Montserrat'] leading-[21px]">
            Lesson 4/10 from the course{" "}
          </span>
          <span className="text-[#5a9863] text-sm font-medium font-['Montserrat'] leading-[21px]">
            {videoData?.title}
          </span>
        </div>
      </div>
    </div>
  );
}
