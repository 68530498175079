import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";

export function ResetPasswordSuccess() {
  const navigate = useNavigate();
  const handleLoginClick = () => {
    // clearCookies();
    navigate("/login");
  };
  return (
    <div className="rounded-lg w-[438px] p-8 bg-white flex flex-col items-center mx-3">
      <div className="rounded-full border-8 border-[#b6efc7]/25">
        <div className="w-14 h-14 bg-[#B6EFC7] justify-center items-center gap-2.5 flex rounded-full">
          <CheckIcon className="w-[22px] h-[22px]" />
        </div>
      </div>
      <div className="text-black text-[28px] font-semibold text-center mt-1 mb-4">
        Your password has been changed successfully!
      </div>
      <Button
        className="!rounded-full w-full mt-8"
        variant="primary"
        onClick={handleLoginClick}
      >
        Go to login
      </Button>
    </div>
  );
}
